import {
  Table,
  Spin,
  Empty,
} from 'antd';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getPermissions } from '../../api/services/appAuthServices';
import {Header} from 'antd/es/layout/layout';
import {
  getAllEventsAsAdmin,
  getEventsForOwner,
} from '../../api/services/EventServices';
import {ModalCreateNewEvent} from './ModalCreateNewEvent';
import {useNavigate} from 'react-router-dom';



export function EventsTable() {
  const navigate = useNavigate();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const source = axios.CancelToken.source();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);

  const columns = [
    {
      title: 'event ID',
      dataIndex: 'eventId',
      key: 'eventId',
    },
    { title: 'eventName',
      dataIndex: 'eventName',
      key: 'eventName',
      sorter: (a, b) => a.eventName.length - b.eventName.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (item) => (
          <>
            <a
                onClick={() => showDrawer(item.eventId)}
                key={`a-${item.eventId}`}
            >
              Details
            </a>
          </>
      ),
    },
  ];

  const showDrawer = (item) => {
    navigate(`/events/${item}`);
  };

  const fetchData = () => {
    getAllEventsAsAdmin()
      .then((response) => {
        setData(response.data);
        setFetching(false);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setFetching(false);
      });
  }



  useEffect(() => {
    fetchData();
    return function cleanup() {
      source.cancel();
    };
  }, []);

  const renderData = () => {
    if (fetching) {
      return <Spin indicator={antIcon} />;
    }
    if (data.length <= 0) {
      return (
        <>
          <Empty />
        </>
      );
    }

    return (
      <>
        <Table
          dataSource={data}
          columns={columns}
          bordered
          title={() => <></>}
          pagination={{ pageSize: 50 }}
          rowKey={(data) => data.eventId}
        />
      </>
    );
  };

  return (
    <>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <Header
            style={{
              background: '#fff'
            }}>
          <ModalCreateNewEvent fetchData={fetchData} />

        </Header>
        {renderData()}
      </div>
    </>
  );
}

