import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import {AuthContext} from '../../context/AuthContext';
import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Checkbox,
  Input, Modal,
  notification,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {
  errorNotification, infoNotification, successNotification,
} from '../Notification';
import {
  getAllAccountRequests,
  getAllCreatorEnvironments,
  getAllEnvironments,
  getAllSubmissions,
} from '../../api/services/creatorServices';
import {Link, useNavigate} from 'react-router-dom';
import DefaultPageLayout from '../DefaultPageLayout';
import ContentLayoutEnvironments from './ContentLayoutEnvironments';
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import EnvironmentDetailsDrawer from './EnvironmentDetailsDrawer';
import {DeActivateEnvironment} from './DeActivateEnvironment';
import BuildUploader from '../devtools/BuildUploader';
import AssetBundleUploader from './AssetBundleUploader';
import {deletePublishedEnvironment} from '../../api/services/devTools';

function CreatorsEnvironmentsOverview() {
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);

  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const {user} = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState('');
  const [data, setData] = useState([]);

  const [visible, setVisible] = useState(false);

  const showDrawer = (item) => {
    navigate('/creators/accounts/requests/' + item);
    console.log(item);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const columns = (fetchData) => [
    {
      title: '',
      dataIndex: 'metadataPreviewImage',
      width: 82,
      key: 'avatar',
      render: (text, item) => (<>
            {item.metadataPreviewImage ? <>
              <Avatar shape="square"
                      size={50}
                      src={item.metadataPreviewImage.url256}/>
            </> : <></>}

          </>),
    }, {
      title: 'Environment Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      }, ...getColumnSearchProps('name'),
      render: (submissionUuid) => (
          <Tooltip placement="topLeft" title={submissionUuid}>
            {submissionUuid}
          </Tooltip>),
    }, {
      title: 'Active', // dataIndex: 'notLocked',
      // filters: [
      //   {
      //     text: 'Active',
      //     value: "true",
      //   },
      //   {
      //     text: 'Deactivated',
      //     value: "false",
      //   },
      // ],
      // dataIndex: 'active',
      key: 'active', render: (text, item) => (<>
            {/*<Checkbox disabled checked={item.active}/>*/}
            {/*<Switch disabled={true} checked={item.active}/>*/}
        <DeActivateEnvironment environment={item} callBack={fetchData} />
          </>),
    }, {
      title: 'Public', width: 100, // dataIndex: 'notLocked',
      key: 'public', render: (text, item) => (<>

            {item.publicEnvironment ? <Tag color="orange">PUBLIC</Tag> : <Tag
                color="blue">PRIVATE</Tag>}
            {/*<Checkbox disabled checked={item.active}/>*/}
          </>),
    },

    {
      title: 'UUID',
      dataIndex: 'environmentUuid',
      key: 'environmentUuid', ...getColumnSearchProps('environmentUuid'),

      // render: (item) => item.environmentUuid,
    }, {
      title: 'Actions', render(item) {
        return (
            <>
              <Button onClick={() => handleShowDetails(item)}>
                More details
              </Button>
              <Popconfirm
                  placement="topRight"
                  title={`Are you sure delete this environment? It will potentially also deleted all spaces that use this environment.`}
                  onConfirm={() => deleteEnvironment(item.environmentUuid)}
                  okText="Yes"
                  cancelText="No"
              >

              <Button danger >
                Delete Environment
              </Button>
              </Popconfirm>
            </>);
      },
    },

  ];

  function handleShowDetails(environment) {
    if (environment) {
      setSelectedEnvironment(environment);
      setShowDetailsDrawer(true);
    }
  }

  async function deleteEnvironment(environmentUuid) {
    await deletePublishedEnvironment(environmentUuid).then((res) => {
      if (res.status === 204) {
        successNotification('Environment deleted successfully');
        fetchData();
      } else {
        errorNotification('Error deleting environment');
      }
    });
  }

  function handleOnPreview(environmentUuid) {
    navigate(
        `https://app.ravel.world/creators/environments/preview/${environmentUuid}`);
  }

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (<div
            style={{
              padding: 8,
            }}
        >
          <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(
                  e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm,
                  dataIndex)}
              style={{
                marginBottom: 8, display: 'block',
              }}
          />
          <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined/>}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
            >
              Filter
            </Button>
          </Space>
        </div>),
    filterIcon: (filtered) => (<SearchOutlined
            style={{
              color: filtered ? '#1890ff' : undefined,
            }}
        />),
    onFilter: (value, record) => record[dataIndex].toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => searchedColumn === dataIndex ? (<Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069', padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
        />) : (text),
  });

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllEnvironments();
      if (response.status === 200) {
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at);
          });
          setData(response.data);
          setFetching(false);
        } else {
          setData([]);
          setFetching(false);
        }
      } else {
        setData([]);
        setFetching(false);
      }
    } catch (e) {
      errorNotification('Error',
          `an error occurred while fetching data: ${e.message}`);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderData = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (<>
          <EnvironmentDetailsDrawer
              showDetailsDrawer={showDetailsDrawer}
              setShowDetailsDrawer={setShowDetailsDrawer}
              environment={selectedEnvironment}
              refreshOverview={fetchData}
          />

          <Table
              dataSource={data}
              columns={columns(fetchData)}
              bordered
              pagination={{pageSize: 10}}
              // scroll={{y: 500}}
              rowKey={(data) => data.environmentUuid}
              expandable={{
                expandedRowRender: (record) => (<>
                      <p
                          style={{
                            margin: 0,
                          }}
                      >
                        Short summary: {record.shortSummary}
                      </p>
                      <p
                          style={{
                            margin: 0,
                          }}
                      >
                        Long summary: {record.longSummary}
                      </p>
                    </>

                ), rowExpandable: (record) => record.name !== 'Not Expandable',
              }}


          />
        </>);
  };

  return (<>
        <DefaultPageLayout>
          <ContentLayoutEnvironments>
            {renderData()}
          </ContentLayoutEnvironments>
        </DefaultPageLayout>
      </>);
}

export default CreatorsEnvironmentsOverview;
