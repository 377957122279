import {
  Table,
  Breadcrumb,
  Spin,
  Empty,
  Modal,
  Space,
  Select,
  Button, Radio, Popconfirm,
} from 'antd';
import {
  DownOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getPermissions } from '../../api/services/appAuthServices';
import {Header} from 'antd/es/layout/layout';
import ModalAddPermission from './ModalAddPermission';



function AppAuthPermissionsTable() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const source = axios.CancelToken.source();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);

  const columns = [
    {
      title: 'appPermissionName',
      dataIndex: 'appPermissionName',
      key: 'appPermissionName',
      sorter: (a, b) => a.appRoleName.length - b.appRoleName.length,
      sortDirections: ['descend'],
    },
    { title: 'description', dataIndex: 'description', key: 'description' },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   render: (a, b) => (
    //       <Radio.Group>
    //         <Popconfirm
    //             placement="topRight"
    //             title={`Are you sure to unlock the account of: ${b.appRoleName}`}
    //             // onConfirm={() => unLock(user.email, fetchUsers)}
    //             okText="Yes"
    //             cancelText="No"
    //         >
    //           <Button value="small">Unlock account</Button>
    //         </Popconfirm>
    //         <Popconfirm
    //             placement="topRight"
    //             title={`Are you sure to de-activate the account of: ${b.appRoleName}`}
    //             // onConfirm={() => deActivate(user.email, fetchUsers)}
    //             okText="Yes"
    //             cancelText="No"
    //         >
    //           <Button value="small">De-activate account</Button>
    //         </Popconfirm>
    //       </Radio.Group>
    //   ),
    // },
  ];

  const fetchPermissions = () => {
    getPermissions()
      .then((response) => {
        setData(response.data);
        setFetching(false);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setFetching(false);
      });
  }



  useEffect(() => {
    fetchPermissions();
    return function cleanup() {
      source.cancel();
    };
  }, []);

  const renderData = () => {
    if (fetching) {
      return <Spin indicator={antIcon} />;
    }
    if (data.length <= 0) {
      return (
        <>
          <Empty />
        </>
      );
    }

    return (
      <>
        <Header
            style={{
              background: '#fff'
            }}>
          <ModalAddPermission refreshPermissions={fetchPermissions} />

        </Header>

        <Table
          dataSource={data}
          columns={columns}
          bordered
          title={() => <></>}
          pagination={{ pageSize: 50 }}
          rowKey={(data) => data.appRoleName}
        />
      </>
    );
  };

  return (
    <>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        {renderData()}
      </div>
    </>
  );
}

export default AppAuthPermissionsTable;
