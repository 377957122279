import { Tag } from 'antd';
import React from 'react';

export const AuthoritiesTags = (authorities) => {
  return (
    <>
      {authorities.authorities.map((tag) => {
        let color = tag;
        if (tag === 'user:read') {
          color = 'blue';
        }
        if (tag === 'user:create') {
          color = 'blue';
        }
        if (tag === 'user:update') {
          color = 'blue';
        }
        if (tag === 'user:delete') {
          color = 'blue';
        }
        if (tag === 'user_admin:access') {
          color = 'cyan';
        }
        if (tag === 'dev_ext:access') {
          color = 'yellow';
        }
        if (tag === 'dev:access') {
          color = 'yellow';
        }
        if (tag === 'admin:access') {
          color = 'gold';
        }
        if (tag === 'super_admin:access') {
          color = 'volcano';
        }
        if (tag === 'system_user:access') {
          color = 'purple';
        }
        return (
          <Tag color={color} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        );
      })}
    </>
  );
};
