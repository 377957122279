import {Button, Checkbox, Form, Input, Modal} from 'antd';
import React, { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import {addPermission} from '../../api/services/appAuthServices';
import {errorNotification, successNotification} from '../Notification';

const ModalAddPermission = ({refreshPermissions}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(false);
    setVisible(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    submitToApi(values);
  }

  const handleCancel = () => {
    setLoading(false);
    setVisible(false);
  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const submitToApi = (values) => {
    addPermission(values)
        .then((response) => {
          if(response.status === 409) {
            errorNotification(response.data.status + " " + response.data.error,
                response.data.message, 'bottomRight');
            setLoading(false);
          }
            if(response.status === 201){
            setLoading(false);
            successNotification(
                'Permission successfully added',
                `${values.name} was added to the system`
            );
            refreshPermissions();
          }
        }
        )
        .catch((err) => {
          console.log(err);
          err.response.json().then((res) => {
            setLoading(false);
            console.log(res);
            errorNotification(
              'There was an issue',
              `${res.message} [${res.status}] [${res.error}]`,
              'bottomRight'
            );
          });
        })
  }



  const renderForm = () => {
    return (
        <>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                label="appPermissionName"
                name="appPermissionName"
                rules={[
                  {
                    required: true,
                    message: 'Please provide appPermissionName!',
                  },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please provide a description!',
                  },
                ]}
            >
              <TextArea
                  showCount
                  maxLength={100}
                  style={{
                    height: 75,
                  }}
              />

            </Form.Item>

            <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
            >
              <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}

              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
    )
  }

  return (
      <>
        <Button type="primary" onClick={showModal}>
          Add permission
        </Button>
        <Modal
            visible={visible}
            title="Add permission"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              // <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
              //   Add
              // </Button>,
              <Button
                  // key="link"
                  // href="https://google.com"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
              >
                Done
              </Button>,
            ]}
        >
          {renderForm()}

        </Modal>
      </>
  );
};

export default ModalAddPermission;
