import client from '../client';
import Cookies from 'js-cookie';

const token = Cookies.get('token');
const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

export const getUserFiles = async () => {
  try {
    const response = await client.get(apiUrl + "/api/v1/userfiles");
    return response;
  } catch (e) {
    console.error(e);
  }
};
