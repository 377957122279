import {useState, useEffect, useRef} from 'react';

import {
  Table,
  Spin,
  Empty,
  Button,
  Avatar,
  Tooltip,
  Radio,
  Space,
  Popconfirm,
  Breadcrumb, Tabs,
  Tag, PageHeader, Menu, Input, Switch,
} from 'antd';
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  PlusOutlined, SearchOutlined, UserOutlined,
} from '@ant-design/icons';
import UsersDrawerForm from './UserDrawerForm';
import {
  deActivateAccount,
  getUsers,
  unLockAccount,
} from '../../api/services/userService';
import axios from 'axios';
import React from 'react';
import ContentLayoutUsers from './ContentLayoutUsers';
import Highlighter from 'react-highlight-words';
import {AuthoritiesTags} from '../AuthoritiesTags';
import {DeActivateAccount} from './DeActivateAccount';
import {DeleteAccount} from './DeleteAccount';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const source = axios.CancelToken.source();



function UsersList() {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
            style={{
              padding: 8,
            }}
        >
          <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
          />
          <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
            >
              Filter
            </Button>
          </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : undefined,
            }}
        />
    ),
    onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{
                  backgroundColor: '#ffc069',
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
  });

  const [users, setUsers] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);

  const columns = (fetchUsers) => [
    {
      title: '',
      dataIndex: 'avatar',
      width: 82,
      key: 'avatar',
      render: (text, user) => (
          <Avatar shape="square" size={50} src={user.profileImageUrl} />
      ),
    },

    {
      title: 'firstName',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'lastName',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),

    },
    {
      title: 'UserUuid',
      dataIndex: 'userUUID',
      key: 'userUUID',
      ...getColumnSearchProps('userUUID'),
      render: (userUUID) => (
          <Tooltip placement="topLeft" title={userUUID}>
            {userUUID}
          </Tooltip>
      ),
    },
    {
      title: 'System Role',
      width: 200,
      dataIndex: 'role',
      key: 'role',
      filters: [
        {
          text: 'External Roles',
          value: 'Category 1',
          children: [
            {
              text: 'ROLE_USER',
              value: 'ROLE_USER',
            },
            {
              text: 'ROLE_USER_ADMIN',
              value: 'ROLE_USER_ADMIN',
            },
          ],
        },
        {
          text: 'Internal Roles',
          value: 'Category 2',
          children: [
            {
              text: 'ROLE_DEV_EXT',
              value: 'ROLE_DEV_EXT',
            },
            {
              text: 'ROLE_DEV',
              value: 'ROLE_DEV',
            },
            {
              text: 'ROLE_ADMIN',
              value: 'ROLE_ADMIN',
            },
            {
              text: 'ROLE_SUPER_ADMIN',
              value: 'ROLE_SUPER_ADMIN',
            },
            {
              text: 'ROLE_ADMIN_USER',
              value: 'ROLE_ADMIN_USER',
            },
          ],
        },
      ],
      onFilter: (value, record) => record.role.includes(value),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'System Authorities',
      dataIndex: 'authorities',
      key: 'authorities',
      filterMode: 'tree',
      filterSearch: true,
      render: (authorities) => (
           <AuthoritiesTags authorities={authorities} />
      ),
    },
    {
      title: 'Account Active',
      // text: 'cannot',
      width: 100,
      // dataIndex: 'active',
      key: 'userUUID',
      render: (user) => (
          <>
            <DeActivateAccount user={user} callBack={fetchUsers} />
          </>
      )
    },
    {
      title: 'Account Not Locked',
      width: 100,
      // dataIndex: 'notLocked',
      key: 'userUUID',
      render: (text, user) => (
          <>
            <Switch disabled={true} checked={user.notLocked} />
          </>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, user) => (
          <>
          <DeleteAccount user={user} callBack={fetchUsers} />
          </>
      ),
    },
  ];


  const fetchStudents = () =>
    getUsers()
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {})
      .finally(() => setFetching(false));
  useEffect(() => {
    fetchStudents();
  }, []);

  const renderUsers = () => {
    if (fetching) {
      return <Spin indicator={antIcon} />;
    }
    if (users.length <= 0) {
      return (
        <>
          {/*<Button*/}
          {/*  onClick={() => setShowDrawer(!showDrawer)}*/}
          {/*  type="primary"*/}
          {/*  shape="round"*/}
          {/*  icon={<PlusOutlined />}*/}
          {/*  size="small"*/}
          {/*>*/}
          {/*  Add New User*/}
          {/*</Button>*/}
          {/*<UsersDrawerForm*/}
          {/*  showDrawer={showDrawer}*/}
          {/*  setShowDrawer={setShowDrawer}*/}
          {/*  fetchStudents={fetchStudents}*/}
          {/*/>*/}
          {/*<Empty />*/}
        </>
      );
    }
    return (
      <>
        {/*<UsersDrawerForm*/}
        {/*  showDrawer={showDrawer}*/}
        {/*  setShowDrawer={setShowDrawer}*/}
        {/*  fetchStudents={fetchStudents}*/}
        {/*/>*/}

        {/*<UserLishtSearch data={users}/>*/}

        <Table
          dataSource={users}
          columns={columns(fetchStudents)}
          bordered
          title={() => (
            <>
              {/*<Button*/}
              {/*  onClick={() => setShowDrawer(!showDrawer)}*/}
              {/*  type="secundary"*/}
              {/*  shape="round"*/}
              {/*  icon={<PlusOutlined />}*/}
              {/*  size="small"*/}
              {/*>*/}
              {/*  Add new user as SUPER_ADMIN*/}
              {/*</Button>*/}
            </>
          )}
          pagination={{ pageSize: 50 }}
          scroll={{ x: 1500 }}
          rowKey={(user) => user.userUUID}
        />
      </>
    );
  };

  return (
    <>
      <ContentLayoutUsers>
        {renderUsers()}
      </ContentLayoutUsers>
    </>
  );
}

export default UsersList;
