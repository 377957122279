import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
  Badge,
  Breadcrumb,
  Button,
  Descriptions,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { errorNotification, successNotification } from '../Notification';
import {
  approveSubmission,
  getAllSubmissions,
  getSubmission,
} from '../../api/services/creatorServices';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultPageLayout from '../DefaultPageLayout';
import Text from 'antd/es/typography/Text';
import ContentLayoutEnvironments from './ContentLayoutEnvironments';

function CreatorEnvironmentSubmissionsAprove() {
  const { submissionUuid } = useParams();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({});

  const [visible, setVisible] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getSubmission(submissionUuid);
      if (response.status === 200) {
        setData(response.data);
        setFetching(false);
      } else {
        setData({});
        setFetching(false);
      }
    } catch (e) {
      errorNotification(
        'Error',
        `an error occurred while fetching data: ${e.message}`
      );
    }
  }, []);

  function onClick(bool) {
    approveSubmission(data.environment.environmentUuid, submissionUuid, bool)
      .then(() => {
        successNotification('Success', 'Submission processed successfully');
        fetchData();
      })
      .catch((e) => {
        errorNotification(
          'Error',
          `an error occurred while approving submission: ${e.message}`
        );
      });
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderData = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <Descriptions
          title="Submission details"
          bordered
          extra={
            <>
              <Popconfirm
                placement="topRight"
                title={`Are you sure to approve submission?`}
                onConfirm={() => onClick(false)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="secundary">Decline submission</Button>
              </Popconfirm>
              <Popconfirm
                placement="topRight"
                title={`Are you sure to decline submission?`}
                onConfirm={() => onClick(true)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Approve submission</Button>
              </Popconfirm>
            </>
          }
        >
          <Descriptions.Item label="Environment name" span={3}>
            {data.environment.name}
          </Descriptions.Item>
          <Descriptions.Item label="Environment UUID" span={3}>
            {data.environment.environmentUuid}
          </Descriptions.Item>
          <Descriptions.Item label="Submission UUID" span={3}>
            {data.submissionUuid}
          </Descriptions.Item>
          <Descriptions.Item label="Created at" span={3}>
            {data.created_at}
          </Descriptions.Item>
          <Descriptions.Item label="Updated at" span={3}>
            {data.updatedAt}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            <Badge status="processing" text="in progress" />
          </Descriptions.Item>
          <Descriptions.Item label="Approved" span={3}>
            {data.approved.toString()}
          </Descriptions.Item>
          <Descriptions.Item label="Denied" span={3}>
            {data.denied.toString()}
          </Descriptions.Item>

          <Descriptions.Item label="Short summary" span={3}>
            {data.environment.shortSummary}
          </Descriptions.Item>
          <Descriptions.Item label="Long summary" span={3}>
            {data.environment.longSummary}
          </Descriptions.Item>
          <Descriptions.Item label="Asset Bundle Url" span={3}>
            <Text code copyable>
              <a
                href={data.environment.metadataAssetBundle.assetBundleUrl}
                target="_blank"
              >
                {' '}
                {data.environment.metadataAssetBundle.assetBundleUrl}
              </a>
            </Text>{' '}
            <Button onClick={() => {
              window.open(
                  `https:app.ravel.world/creators/environments/preview/${data.environment.environmentUuid}`);
            }}>enter</Button>
          </Descriptions.Item>
          <Descriptions.Item label="Preview image" span={3}>
            <img
              width={500}
              alt="environment image"
              src={data.environment.metadataPreviewImage.previewImageUrl}
            />
            <Text code copyable>
              <a
                href={data.environment.metadataPreviewImage.previewImageUrl}
                target="_blank"
              >
                {' '}
                {data.environment.metadataPreviewImage.previewImageUrl}
              </a>
            </Text>{' '}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  return (
    <>
      <DefaultPageLayout>
        <ContentLayoutEnvironments>
          {renderData()}
         </ContentLayoutEnvironments>

      </DefaultPageLayout>
    </>
  );
}

export default CreatorEnvironmentSubmissionsAprove;
