import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Cookies from 'js-cookie';
import client from '../../api/client';
import {useEffect, useState} from 'react';
import {Select} from 'antd';
import {Option} from 'antd/es/mentions';
import Text from 'antd/es/typography/Text';
import DefaultPageLayout from '../../components/DefaultPageLayout';


// https://www.npmjs.com/package/swagger-ui-react

function Dashboard() {
  const [swagger, setSwagger] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [state, setState] = useState(false)

  const token = Cookies.get('token');
  const requestInterceptor = (req) => (
      {
        ...req,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
  );


 const fetchData = async (value) => {
    try {
      const response = await client.get(
          value,
      );
      setState(true);
      setSwagger(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

 function renderApiDocs() {
   if (!state) {
     return <><Text type={'warning'}> no documentation selected</Text></>
   }
   if (loading) {
      return <div>Loading...</div>;
   }
    return (
        <SwaggerUI
            requestInterceptor={requestInterceptor}
            showMutatedRequest={true}
            displayRequestDuration={true}
            docExpansion={'list'}
            persistAuthorization={true}
            withCredentials={true}
            filter={true}
            spec={swagger} />
    );
  }

  useEffect(() => {
    // fetchData();
  }, []);

 function onChange(value){
    setSelectedOption(value);
    fetchData(value);
 }

  function onSearch(val) {
    console.log('search:', val);
  }


  return (
    <DefaultPageLayout>
      <Select
          showSearch
          placeholder="Select documentation"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={(input, option) =>
              option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
          }
      >
        <Option
            value="https://dev.ravel.systems/v3/api-docs">
          Monolith
        </Option>
        <Option
            value="https://dev.ravel.systems/environments/api-docs">
          Environments
        </Option>
        <Option
            value="https://dev.ravel.systems/devtools/api-docs">
          Dev Tools
        </Option>

      </Select>
      {/*<ShortcutTable></ShortcutTable>*/}
      {renderApiDocs()}

    </DefaultPageLayout>
  );
}

export default Dashboard;
