import client from '../client';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
const token = Cookies.get('token');

const getEnvPro = async () => {
  try {
    const { data } = await client.get(
      apiUrl + '/api/v1/spaces/env/?environmentAssignee=PUBLIC'
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const createEnvironmentPro = async (payload) => {
  try {
    const { responseData } = await client.post(
      apiUrl + '/api/v1/admin/spaces/env/pro',
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return responseData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export { getEnvPro, createEnvironmentPro };
