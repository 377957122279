import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Modal,
  Row,
  Switch,
  Tag,
  Typography,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {errorNotification, successNotification} from '../Notification';
import {getTokenGatedInformation} from '../../api/services/SpacesGateServices';
import {getSpaceProWithUuid} from '../../api/services/spacesProServices';
import {ConvertDate} from '../../helpers/ConvertDate';
import {getSpacesForEnvironment} from '../../api/services/creatorServices';
import AssetBundleUploader from './AssetBundleUploader';

const {Paragraph, Text, Link} = Typography;

function EnvironmentDetailsDrawer({
  showDetailsDrawer, setShowDetailsDrawer, environment, refreshOverview,
}) {
  const onCLose = () => setShowDetailsDrawer(false);
  const [data, setData] = useState({});
  const [fetching, setFetching] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);


  async function getSpaceDetails() {
    try {
      const response = await getSpacesForEnvironment(
          environment.environmentUuid);
      if (response.status === 200) {
        setData(response.data.spacePro);
      } else {
        setFetching(false);
        errorNotification('Failed to retrieve space details');
      }
    } catch (e) {
      setFetching(false);
      console.error(e);
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (environment && showDetailsDrawer) {
      getSpaceDetails()
          .finally(() => setFetching(false));
    }
  }, [showDetailsDrawer, environment]);

  const refreshAll = () => {
    refreshOverview();
    setIsModalVisible(false);
    setShowDetailsDrawer(false);
  }

  const renderSpaces = () => {

    //if data array is empty, return null
    if (data.length === 0) {
      return <Descriptions.Item label="Spaces" span={3}>No spaces
        found</Descriptions.Item>;
    }
    if (data.length > 0) {
      return (<>


        {data.map((item) => (<>
              <br/>
              <Descriptions
                  title={`Space: ${item.spaceName}`}
                  bordered
                  extra={<>
                  </>}
              >
                <Descriptions.Item label="Name"
                                   span={3}
                >
                  {item.spaceName}
                </Descriptions.Item>
                <Descriptions.Item label="UUID"
                                   span={3}
                >
                  <Text copyable>{item.spaceUuid}</Text>

                </Descriptions.Item>
              </Descriptions>
            </>

        ))}


      </>);
    }

  };

  const renderDetails = () => {
    if (fetching) {
      return <></>;
    }
    return (<>
      <>
        <Descriptions
            title="Environment details"
            bordered
            extra={
          <>
            <Button onClick={showModal} > Upload new assetbundle</Button>
              <Modal
                  title="Upload new assetbundle"
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
              >
                <AssetBundleUploader
                    environment={environment}
                    fetchData={refreshAll}
                />
              </Modal>
            </>
        }
        >
          <Descriptions.Item label="Environment Name" span={3}>
            {`${environment?.name}`}
          </Descriptions.Item>
          <Descriptions.Item label="Environment Uuid" span={3}>
            {`${environment?.environmentUuid}`}
          </Descriptions.Item>

          <Descriptions.Item label="Short summary" span={3}>
            {`${environment?.shortSummary}`}
          </Descriptions.Item>

          <Descriptions.Item label="Long summary" span={3}>
            {`${environment?.longSummary}`}
          </Descriptions.Item>


          <Descriptions.Item label="created at" span={3}>
            {ConvertDate(environment?.created_at)}
          </Descriptions.Item>

          <Descriptions.Item label="updated at" span={3}>
            {ConvertDate(environment?.updatedAt)}
          </Descriptions.Item>


          <Descriptions.Item label="Asset Bundle Url" span={3}>
            <Link href={environment.assetBundleUrl}
                  target="_blank"> {environment.assetBundleUrl}  </Link>
          </Descriptions.Item>


        </Descriptions>
        <br/>


        {environment?.creator &&

            <Descriptions
                title="Creator details"
                bordered
                extra={<>
                </>}
            >
              <Descriptions.Item label="Name" span={3}>
                {environment?.creator.creatorName}
              </Descriptions.Item>
              <Descriptions.Item label="Bio" span={3}>
                {environment?.creator.creatorBio}
              </Descriptions.Item>


            </Descriptions>}


        <br/>

        {renderSpaces()}


      </>
    </>);
  };

  return (<Drawer
      width={740}
      placement="right"
      closable={true}
      onClose={onCLose}
      visible={showDetailsDrawer}
  >
    {showDetailsDrawer && <>{renderDetails()}</>}
  </Drawer>);
}

export default EnvironmentDetailsDrawer;
