import { Drawer, Input, Col, Select, Form, Row, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { successNotification, errorNotification } from '../Notification';
import { addUserSysAdmin } from '../../api/services/userService';
import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function UserDrawerForm({ showDrawer, setShowDrawer, fetchStudents }) {
  const onCLose = () => setShowDrawer(false);
  const [submitting, setSubmitting] = useState(false);

  const onFinish = (user) => {
    setSubmitting(true);
    addUserSysAdmin(user)
      .then(() => {
        onCLose();
        successNotification(
          'User successfully added',
          `${user.firstName} was added to the system`
        );
        fetchStudents();
      })
      .catch((err) => {
        console.log(err);
        err.response.json().then((res) => {
          console.log(res);
          errorNotification(
            'There was an issue',
            `${res.message} [${res.status}] [${res.error}]`,
            'bottomLeft'
          );
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    alert(JSON.stringify(errorInfo, null, 2));
  };

  return (
    <Drawer
      title="Add new user without invite and organization"
      width={360}
      onClose={onCLose}
      visible={showDrawer}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onCLose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
        </div>
      }
    >
      <Paragraph>
        Add a new user to the system without inviting them to an organization or
        sending a invite.
      </Paragraph>
      <Paragraph>
        <Text>
          {' '}
          To use this function you need to System Authority:{' '}
          <Text code>super_admin:access</Text>{' '}
        </Text>
      </Paragraph>
      <Form
        layout="vertical"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        // hideRequiredMark
        autoComplete="off"
      >
        <Row>
          <Col span={20}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please enter a email' },
                { type: 'email' },
                {
                  type: 'string',
                  min: 1,
                  max: 50,
                  warningOnly: true,
                  message: 'Too long',
                },
                {
                  type: 'email',
                  warningOnly: true,
                  message: 'Please enter a email',
                },
              ]}
            >
              <Input placeholder="Please enter an email" />
            </Form.Item>
            <Form.Item
              name="firstName"
              label="firstName"
              rules={[
                {
                  type: 'string',
                  min: 1,
                  max: 50,
                  warningOnly: true,
                  message: 'Too long',
                },
                { required: true, message: 'Please enter a firstName' },
              ]}
            >
              <Input placeholder="Please enter user firstName" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="lastName"
              rules={[{ required: true, message: 'Please provide a lastName' }]}
            >
              <Input placeholder="Please enter user lastName" />
            </Form.Item>
            <Form.Item
              name="role"
              label="role"
              rules={[{ required: true, message: 'Please select a role' }]}
            >
              <Select>
                <Select.Option value="ROLE_USER">USER</Select.Option>
                <Select.Option value="ROLE_USER_ADMIN">
                  USER_ADMIN
                </Select.Option>
                <Select.Option value="ROLE_DEV">DEV</Select.Option>
                <Select.Option value="ROLE_ADMIN">ADMIN</Select.Option>
                <Select.Option value="ROLE_SUPER_ADMIN">
                  SUPER_ADMIN
                </Select.Option>
                <Select.Option value="ROLE_ADMIN_USER">
                  SYSTEM_USER
                </Select.Option>
              </Select>

              {/*<Input placeholder="Please enter user role"/>*/}
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  type: 'string',
                  min: 6,
                  warningOnly: true,
                  message: 'Too short',
                },
                {
                  type: 'string',
                  max: 24,
                  warningOnly: true,
                  message: 'Too long',
                },
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            {/*<Form.Item*/}
            {/*    name="email"*/}
            {/*    label="Email"*/}
            {/*    rules={[{required: true, message: 'Please enter Users email'}]}*/}
            {/*>*/}
            {/*    <Input placeholder="Please enter user email"/>*/}
            {/*</Form.Item>*/}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row>{submitting && <Spin indicator={antIcon} />}</Row>
      </Form>
    </Drawer>
  );
}

export default UserDrawerForm;
