import DefaultPageLayout from '../../components/DefaultPageLayout';
import AppAuthPermissionsTable from '../../components/applicationAuth/AppAuthPermissionsTable';
import ContentLayoutAppAuth
  from '../../components/applicationAuth/ContentLayoutAppAuth';

function ApplicationPermissions() {
  return (
    <DefaultPageLayout>
      <ContentLayoutAppAuth>
        <AppAuthPermissionsTable></AppAuthPermissionsTable>

      </ContentLayoutAppAuth>
    </DefaultPageLayout>
  );
}

export default ApplicationPermissions;
