import DefaultPageLayout from '../../components/DefaultPageLayout';
import UsersList from '../../components/users/UsersList';

function Users() {
  return (
    <DefaultPageLayout>
      <UsersList></UsersList>
    </DefaultPageLayout>
  );
}

export default Users;
