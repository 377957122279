import DefaultPageLayout from '../../components/DefaultPageLayout';
import NotFound from '../404NotFound';

function SpacesLight() {
  return (
    <DefaultPageLayout>
      <NotFound></NotFound>
    </DefaultPageLayout>
  );
}

export default SpacesLight;
