import {Menu} from 'antd';
import {NavLink} from 'react-router-dom';
import {Header} from 'antd/lib/layout/layout';
import React from 'react';


const ContentLayoutAppAuth = ({children}) => {
  return (
      <>
        <Header
            style={{
              background: '#fff',
            }}
        >
          <Menu
              theme="light"
              mode="horizontal"
          >
            <Menu.Item
                key="SubNav-01"
            >
              App Roles & permissions
              <NavLink
                  to="/applicationRoles" ></NavLink>
            </Menu.Item>
            <Menu.Item
                key="SubNav-02"
            >
              Permissions
              <NavLink to="/applicationPermissions" ></NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
        >
          {children}
        </div>
      </>
  )
}

export default ContentLayoutAppAuth;


// <ContentLayoutSpaces>
//
// </ContentLayoutSpaces>
