import DefaultPageLayout from '../../components/DefaultPageLayout';

import BuildsOverview from '../../components/devtools/BuildsOverView';


function DevToolsBuildsOverview() {
  return (
      <DefaultPageLayout>
        <BuildsOverview></BuildsOverview>
      </DefaultPageLayout>
  );
}
export default DevToolsBuildsOverview;
