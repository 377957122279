import DefaultPageLayout from '../../components/DefaultPageLayout';
import CreatorsAccountsRequestsSection
  from '../../components/creators/CreatorsAccountsRequestsSection';


function CreatorAccountsRequests() {
  return (
    <DefaultPageLayout>
      <CreatorsAccountsRequestsSection></CreatorsAccountsRequestsSection>
    </DefaultPageLayout>
  );
}
export default CreatorAccountsRequests;
