import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import isTokenValid from '../helpers/isTokenValid';
import Cookies from 'js-cookie';
import {
  errorNotification,
} from '../components/Notification';
import ValidateIsUserDev from '../helpers/ValidateIsUserDev';
import client from '../api/client';

export const AuthContext = createContext({});



function AuthContextProvider({ children }) {
  const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
  const navigate = useNavigate();
  const [isAuth, toggleIsAuth] = useState({
    isAuth: false,
    user: null,
    status: 'pending',
    environment: '',
  });

  // const apiUrl = Cookies.get('envUrl');

  const removeUserCookies = () => {
    Cookies.remove('token');
    Cookies.remove('envUrl');
    Cookies.remove('imageUrl');
    Cookies.remove('userUUID');
    Cookies.remove('user');
  };

  useEffect(() => {
    const token = Cookies.get('token');
    if (token && isTokenValid(token)) {
      const decoded = jwt_decode(token);
      fetchUserData(decoded.sub, token);
    } else {
      toggleIsAuth({
        isAuth: false,
        user: null,
        status: 'done',
      });
    }
  }, []);

  function login(JWT, user) {
    const decoded = jwt_decode(JWT);
    if(!ValidateIsUserDev(decoded.authorities)){
      errorNotification('No access');
      return;
    }
    Cookies.set('token', JWT, { expires: 1 });
    Cookies.set('user', JSON.stringify(user), { expires: 1 });
    fetchUserData(apiUrl, JWT, "/stats");

    // fetchUserDataAfterLogin( JWT, '/api-docs');
    // navigate('/api-docs');
}

  function logout() {
    localStorage.clear();
    removeUserCookies();
    toggleIsAuth({
      isAuth: false,
      user: null,
      status: 'done',
    });
    navigate('/login');
  }

  async function fetchUserData(envUrl, token, redirectUrl) {
    try {
      const result = await client.get(apiUrl + `/api/v1/users/self`
      );
      toggleIsAuth({
        ...isAuth,
        isAuth: true,
        user: {
          email: result.data.email,
          userUUID: result.data.userUUID,
          firstName: result.data.firstName,
          lastname: result.data.lastname,
          avatarUrl: result.data.avatarUrl,
          avatarUrlFullBody: result.data.avatarUrlFullBody,
          profileImageUrl: result.data.profileImageUrl,
          authorities: result.data.systemAuthorities,
          systemRole: result.data.systemRole,
        },
        environment: envUrl,
        status: 'done',
      });
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    } catch (e) {
      console.error(e);
      toggleIsAuth({
        isAuth: false,
        user: null,
        status: 'done',
      });
    }
  }

  const contextData = {
    isAuth: isAuth.isAuth,
    user: isAuth.user,
    login: login,
    logout: logout,
    environment: isAuth.environment,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {isAuth.status === 'done' ? children : <p>Loading... </p>}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
