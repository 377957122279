import axios from 'axios';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  deleteSpacePro,
  getAllSpacesAsRavelAdmin,
  getSpaceProOrganization,
} from '../../api/services/spacesProServices';
import { Breadcrumb, Button, List, Modal, Popconfirm, Select } from 'antd';
import NewSpaceProOrgDrawerForm from './NewSpaceProOrgDrawerForm';
import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';
import { useEffect, useState } from 'react';
import { getOrganizations } from '../../api/services/organizationServices';
import { infoNotification, successNotification } from '../Notification';
import ContentLayoutSpaces from './ContentLayoutSpaces';
import {Header} from 'antd/es/layout/layout';
import styled from 'styled-components';
import {SpacesCard} from './SpacesCard';


const source = axios.CancelToken.source();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const delSpacePro = async (payload) => {
  try {
    const { responseData } = await deleteSpacePro(payload);
    return responseData;
  } catch (e) {
    console.error(e);
    throw e;
  }
  return function cleanup() {
    source.cancel();
  };
};

function SpacesProOrgList() {
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [spacePro, setSpacePro] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [spaceProUuid, setSpaceProUuid] = useState('');
  const showModal = (object) => {
    setSpaceProUuid(object.spaceUuid);
    setIsModalVisible(true);
  };

  function showDetails(spacePro) {
    setSpacePro(spacePro);
    setShowDetailsDrawer(true);
  }

  const [options, setOptions] = useState([]);

  const fetchData = (payload) =>
    getSpaceProOrganization(payload.organizationId)
      .then((data) => {
        setData(data);
        if (data.length <= 0) {
          infoNotification(
            'No Spaces has been found for: ' + payload.organizationName
          );
        } else {
          successNotification(
            'Successfully loaded Spaces for: ' + payload.organizationName
          );
        }
      })
      .catch((err) => {})
      .finally(() => setFetching(false));

  const fetchOrganizations = () => {
    getOrganizations()
      .then((data) => {
        console.log(data);
        setOptions(data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchOrganizations();
  }, []);

  function onChange2(value, selectedOptions) {
    fetchData(selectedOptions);
  }

  function filter(inputValue, path) {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

  return (
    <>
        <Header         style={{
          background: '#fff',
        }}>
          <Select
              options={options}
              fieldNames={{
                label: 'organizationName',
                value: 'organizationName',
              }}
              onChange={onChange2}
              placeholder="Please select organization"
              showSearch={{ filter }}
              onSearch={(value) => console.log(value)}
              style={{ width: 230 }}
          />
          <Button
              onClick={() => setShowDrawer(!showDrawer)}
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="small"
          >
            Add new Space Pro
          </Button>
        </Header>
        <NewSpaceProOrgDrawerForm
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            fetchUsers={getAllSpacesAsRavelAdmin}
        />

        <ContentWrapper>
        <SpacesCard data={data} fetching={fetching} fetchData={fetchData}/>
        </ContentWrapper>

    </>
  );
}

export default SpacesProOrgList;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px 20px;
  flex-wrap: wrap;
  justify-content: center;
`;
