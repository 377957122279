import {Button, Checkbox, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import TextArea from 'antd/es/input/TextArea';
import {
  addAssignRoleToPermission,
  addPermission, getPermissions,
} from '../../api/services/appAuthServices';
import {errorNotification, successNotification} from '../Notification';
import Text from 'antd/es/typography/Text';
import {Option} from 'antd/es/mentions';

const ModalAssignPermissionToRole = ({ permissions, selectedRole, refreshRoles}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(false);
    setVisible(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    submitToApi(values);
  }

  const handleCancel = () => {
    setLoading(false);
    setVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const submitToApi = (values) => {
    addAssignRoleToPermission(values.appPermissionName, selectedRole)
        .then((response) => {
          if(response.status === 409) {
            errorNotification(response.data.status + " " + response.data.error,
                response.data.message, 'bottomRight');
            setLoading(false);
          }
            if(response.status === 202){
            setLoading(false);
            successNotification(
                'Permission successfully assigned',
                `${values.name} was assigned to role ${selectedRole.name}`,
            );
              refreshRoles();
          }
        }
        )
        .catch((err) => {
          console.log(err);
          err.response.json().then((res) => {
            setLoading(false);
            console.log(res);
            errorNotification(
              'There was an issue',
              `${res.message} [${res.status}] [${res.error}]`,
              'bottomRight'
            );
          });
        })
  }







  const renderForm = () => {
    return (
        <>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item name="appPermissionName" label="Permission" rules={[{ required: true }]}>
              <Select
                  placeholder="Select a option and change input text above"
                  // onChange={onGenderChange}
                  allowClear
                  options={permissions.map((permission) => {
                    return {
                      value: permission.appPermissionName,
                      label: permission.appPermissionName,

                    }
                  })
                  }
              >
              </Select>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
            >
              <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}

              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
    )
  }

  return (
      <>
        <Button type="primary" danger ghost onClick={showModal}>
          Add permission

        </Button>
        <Modal
            visible={visible}
            title={`Assign permission to role : ${selectedRole}`}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
              >
                Done
              </Button>,
            ]}
        >
          {renderForm()}

        </Modal>
      </>
  );
};

export default ModalAssignPermissionToRole;
