import React, {useState, useEffect, useRef} from 'react';

import {
  Table,
  Spin,
  Empty,
  Tooltip,
  Breadcrumb,
  Input,
  Space,
  Button,
} from 'antd';
import {LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import { getUserInviteList } from '../../api/services/userService';
import axios from 'axios';
import ContentLayoutUsers from './ContentLayoutUsers';
import Highlighter from 'react-highlight-words';

function UserInviteList() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
            style={{
              padding: 8,
            }}
        >
          <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
          />
          <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
            >
              Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
            >
              Filter
            </Button>
          </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : undefined,
            }}
        />
    ),
    onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{
                  backgroundColor: '#ffc069',
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
  });




  const columns = (fetchUsers) => [
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'UserUuid',
      // width: 335,
      dataIndex: 'userUUID',
      key: 'userUUID',
      ellipsis: {
        showTitle: false,
      },
      ...getColumnSearchProps('userUUID'),
      render: (userUUID) => (
        <Tooltip placement="topLeft" title={userUUID}>
          {userUUID}
        </Tooltip>
      ),
    },
    {
      title: 'confirmedAt',
      dataIndex: 'confirmedAt',
      key: 'confirmedAt',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'expiresAt',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
    },
    {
      title: 'Invite Token',
      dataIndex: 'token',
      key: 'token',
      ...getColumnSearchProps('token'),
    },
    {
      title: 'organizationUUID',
      dataIndex: 'organizationUUID',
      key: 'organizationUUID',
      ellipsis: {
        showTitle: false,
      },
      ...getColumnSearchProps('organizationUUID'),
      render: (organizationUUID) => (
          <Tooltip placement="topLeft" title={organizationUUID}>
            {organizationUUID}
          </Tooltip>
      ),
    },
  ];
  const [users, setUsers] = useState([]);
  const [fetching, setFetching] = useState(true);
  const source = axios.CancelToken.source();

  useEffect(() => {
    async function getUserDatas() {
      try {
        const data = await getUserInviteList();
        setUsers(data);
        setFetching(false);
      } catch (e) {
        setFetching(false);
        console.error(e);
      }
    }
    getUserDatas();
    return function cleanup() {
      source.cancel();
    };
  }, []);

  const renderUsers = () => {
    if (fetching) {
      return <Spin indicator={antIcon} />;
    }
    if (users.length <= 0) {
      return (
        <>
          <Empty />
        </>
      );
    }
    return (
      <>
        <Table
          dataSource={users}
          columns={columns()}
          bordered
          title={() => <></>}
          pagination={{ pageSize: 50 }}
          scroll={{ x: 1500, y: 600 }}
          rowKey={(user) => user.userUUID}
        />
      </>
    );
  };

  return (
    <>
      <ContentLayoutUsers>

        {renderUsers()}
      </ContentLayoutUsers>

    </>
  );
}

export default UserInviteList;
