import DefaultPageLayout from '../../components/DefaultPageLayout';

import {ContentLayoutStats} from '../../components/stats/ContentLayoutStats';
import {Line, LineChart, XAxis, YAxis, Tooltip} from 'recharts';
import {useEffect, useState} from 'react';
import {Card, Col, Descriptions, List, Row, Select} from 'antd';
import {
  getStatsSpacesApi,
  getStatsUsersApi,
} from '../../api/services/logService';



const data = {
  "spaceCreated": {
    "2023-05-11": 2,
    "2023-05-10": 0,
    "2023-05-09": 3,
    "2023-05-08": 0,
    "2023-05-07": 0,
    "2023-05-06": 0,
    "2023-05-05": 0,
    "2023-05-04": 0,
    "2023-05-03": 0,
    "2023-05-02": 0
  },
  "spaceUpdated": {
    "2023-05-11": 17,
    "2023-05-10": 0,
    "2023-05-09": 10,
    "2023-05-08": 0,
    "2023-05-07": 0,
    "2023-05-06": 0,
    "2023-05-05": 0,
    "2023-05-04": 0,
    "2023-05-03": 0,
    "2023-05-02": 0
  },
  "spaceDeleted": {
    "2023-05-11": 2,
    "2023-05-10": 0,
    "2023-05-09": 1,
    "2023-05-08": 0,
    "2023-05-07": 0,
    "2023-05-06": 0,
    "2023-05-05": 0,
    "2023-05-04": 0,
    "2023-05-03": 0,
    "2023-05-02": 0
  },
  "spaceInteraction": {
    "2023-05-11": 0,
    "2023-05-10": 0,
    "2023-05-09": 0,
    "2023-05-08": 0,
    "2023-05-07": 0,
    "2023-05-06": 0,
    "2023-05-05": 0,
    "2023-05-04": 0,
    "2023-05-03": 0,
    "2023-05-02": 0
  },
  "spaceRole": {
    "2023-05-11": 256,
    "2023-05-10": 0,
    "2023-05-09": 15,
    "2023-05-08": 0,
    "2023-05-07": 0,
    "2023-05-06": 0,
    "2023-05-05": 0,
    "2023-05-04": 0,
    "2023-05-03": 0,
    "2023-05-02": 0
  },
  "spacePortal": {
    "2023-05-11": 8,
    "2023-05-10": 0,
    "2023-05-09": 61,
    "2023-05-08": 0,
    "2023-05-07": 0,
    "2023-05-06": 0,
    "2023-05-05": 0,
    "2023-05-04": 0,
    "2023-05-03": 0,
    "2023-05-02": 0
  },
  "spaceInteractionValidateEntry": {
    "2023-05-11": 378,
    "2023-05-10": 0,
    "2023-05-09": 65,
    "2023-05-08": 0,
    "2023-05-07": 0,
    "2023-05-06": 0,
    "2023-05-05": 0,
    "2023-05-04": 0,
    "2023-05-03": 0,
    "2023-05-02": 0
  }
}


function transformDataIntoChart(inputData) {
  const result = [];

  Object.keys(inputData).forEach(key => {
    Object.keys(inputData[key]).forEach(date => {
      const itemIndex = result.findIndex(item => item.date === date);

      if (itemIndex !== -1) {
        result[itemIndex][key] = inputData[key][date];
      } else {
        result.push({
          date: date,
          [key]: inputData[key][date]
        });
      }
    });
  });
  result.sort((a, b) => new Date(a.date) - new Date(b.date));
  return result;
}




export const Stats = () => {
  const [dataUsers, setDataUsers] = useState([]);
  const [dataSpaces, setDataSpaces] = useState([]);
  const [chartDataUsers, setChartDataUsers] = useState([]);
  const [chartDataSpaces, setChartDataSpaces] = useState([]);
  const [apiDone, setApiDone] = useState(false);
  const [userLoadingState, setUserLoadingState] = useState(false);
  const [spaceLoadingState, setSpaceLoadingState] = useState(false);

  async function getStatsUsers(days) {
    setUserLoadingState(true);
    await getStatsUsersApi(days).then((response) => {
      console.log(response.data)
      setDataUsers(response.data);
      setChartDataUsers(transformDataIntoChart(response.data));
      setUserLoadingState(false);
    });
  }
  async function getStatsSpaces(days) {
    setSpaceLoadingState(true);
    await getStatsSpacesApi(days).then((response) => {
      console.log(response.data)
      setDataSpaces(response.data);
      setChartDataSpaces(transformDataIntoChart(response.data));
      setSpaceLoadingState(false);
    })
  }

  useEffect(() => {
    getStatsSpaces(4);
    getStatsUsers(4);
    setApiDone(true);
  }, []);

  useEffect(() => {
    if(apiDone){

    }
  }, [apiDone]);



  const renderLineChartsUsers = Object.keys(dataUsers).map((key) => (
      <>
        <Card
            size={"small"}
            title={key}
            style={{ width: 450, margin: 0 }}
        >
          <LineChart key={key} width={400} height={200} data={chartDataUsers}>
            <Line type="monotone" dataKey={key} stroke="#8884d8" />
            <XAxis
                dataKey={"date"}
            />
            <YAxis />
            <Tooltip />
          </LineChart>
        </Card>
      </>
  ));
  const renderLineChartsSpaces = Object.keys(dataSpaces).map((key) => (
      <>
        <Card

            size={"small"}
            title={key}
            style={{ width: 450, margin: 0 }}
        >
          <LineChart key={key} width={400} height={200} data={chartDataSpaces}>
            <Line type="monotone" dataKey={key}
                  stroke="#8884d8"
            animationDuration={2000}

            />
            <XAxis
                dataKey={"date"}
            />
            <YAxis />
            <Tooltip />
          </LineChart>
        </Card>
      </>
  ));


  const handleChange = (value) => {
    getStatsUsers(value);
    getStatsSpaces(value);
  };


  return (
      <DefaultPageLayout>
        <ContentLayoutStats>
          <Card

              style={{ 
                width: "100%"
          }}
          >
            <Descriptions title="Stats">
              <Descriptions.Item label="Select days">
                <Select
                    loading={userLoadingState || spaceLoadingState}
                    defaultValue="4"
                    style={{ width: 120 }}
                    onChange={handleChange}
                    options={[
                      {
                        value: '1',
                        label: '1 day',
                      },
                      {
                        value: '2',
                        label: '2 days',
                      },
                      {
                        value: '3',
                        label: '3 days',
                      },
                      {
                        value: '4',
                        label: '4 days',
                      },
                      {
                        value: '5',
                        label: '5 days',
                      },
                      {
                        value: '6',
                        label: '6 days',
                      },
                    ]}
                />
              </Descriptions.Item>
            </Descriptions>
          </Card>

          {apiDone &&
          <>
          <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
              }}
          >

            {renderLineChartsUsers}
            {renderLineChartsSpaces}
          </div>
          </>
          }

        </ContentLayoutStats>
      </DefaultPageLayout>
  );
}
