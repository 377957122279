import DefaultPageLayout from '../../components/DefaultPageLayout';
import OrganizationUsersList from '../../components/organizations/OrganizationUsersList';

function OrganizationUsers() {
  return (
    <DefaultPageLayout>
      <OrganizationUsersList></OrganizationUsersList>
    </DefaultPageLayout>
  );
}

export default OrganizationUsers;
