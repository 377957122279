import DefaultPageLayout from '../../components/DefaultPageLayout';
import SpacesProOrgList from '../../components/spacesPro/SpacesProOrgList';
import ContentLayoutSpaces
  from '../../components/spacesPro/ContentLayoutSpaces';

function SpacesProOrg() {
  return (
    <DefaultPageLayout>
      <ContentLayoutSpaces>
      <SpacesProOrgList></SpacesProOrgList>
      </ContentLayoutSpaces>
    </DefaultPageLayout>
  );
}

export default SpacesProOrg;
