import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { errorNotification, successNotification } from '../Notification';
import { getAllSubmissions } from '../../api/services/creatorServices';
import { useNavigate } from 'react-router-dom';
import ContentLayoutEnvironments from './ContentLayoutEnvironments';

function CreatorEnvironmentSubmission() {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const { user } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState('');
  const [data, setData] = useState([]);

  const [visible, setVisible] = useState(false);

  const showDrawer = (item) => {
    navigate('/environments/submissions/' + item);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  function convertDate(date) {
    const d = new Date(date);
    return d.toUTCString();
  }


  const columns = (fetchData) => [
    {
      title: '',
      dataIndex: 'environment',
      width: 82,
      key: 'avatar',
      render: (text, item) => (
          <Avatar shape="square" size={50} src={item.environment.metadataPreviewImage.url256} />
      ),
    },
    {
      title: 'submissionUuid',
      dataIndex: 'submissionUuid',
      key: 'submissionUuid',
      ellipsis: {
        showTitle: false,
      },
      render: (submissionUuid) => (
        <Tooltip placement="topLeft" title={submissionUuid}>
          {submissionUuid}
        </Tooltip>
      ),
    },
    {
      title: 'Creator',
      dataIndex: 'environment',
      key: 'environment',
      render: (item) => item.environmentCreator.creatorName,
    },
    {
      title: 'Environment name',
      dataIndex: 'environment',
      key: 'environment',
      render: (item) => item.name,
    },
    {
      title: 'Short summary',
      dataIndex: 'environment',
      key: 'shortSummary',
      render: (item) => (
          <>
            <div>{convertDate(item.created_at)}</div>
          </>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (item) => (
        <>
          <a
            onClick={() => showDrawer(item.submissionUuid)}
            key={`a-${item.environment.submissionUuid}`}
          >
            Review submission
          </a>
        </>
      ),
    },
  ];

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllSubmissions();
      if (response.status === 200) {
        if (response.data.length > 0) {
          const filteredDataIsApproved = response.data.filter(
              (item) => item.approved === false
          );
         const filteredDataIsDenied = filteredDataIsApproved.filter(
              (item) => item.denied === false
          );
         setData(filteredDataIsDenied);
          setFetching(false);
        } else {
          setData([]);
          setFetching(false);
        }
      }
    } catch (e) {
      errorNotification(
        'Error',
        `an error occurred while fetching data: ${e.message}`
      );
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderData = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <Table
          dataSource={data}
          columns={columns(fetchData)}
          bordered
          title={() => (
            <>
              <Tag>Number of open submissions</Tag>
              <Badge count={data.length} className="site-badge-count-4" />
            </>
          )}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 500 }}
          rowKey={(data) => data.submissionUuid}
        />
      </>
    );
  };

  return (
    <>
      <ContentLayoutEnvironments>
        {renderData()}
      </ContentLayoutEnvironments>

    </>
  );
}

export default CreatorEnvironmentSubmission;
