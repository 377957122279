import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import {
  errorNotification,
  successNotification,
} from '../../components/Notification';

import {Form, Input, Button, Layout, Col, Row, Select, Card} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import DefaultPageLayoutOpen from '../../components/DefaultPageLayoutOpen';

function Login() {
  const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

  const subdomain  = /:\/\/([^\/]+)/.exec(window.location.href)[1];

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);


  async function handleSubmit(e) {
    try {
      const result = await axios.post(
          apiUrl + '/v1/auth/login',
        {
          email: email,
          password: password,
        }
      );
      // geef de JWT token aan de login-functie van de context mee
      login(
        result.data.accessToken,
          result.data
      );
      successNotification('Log in successful');
      return result.data;
    } catch (e) {
      console.error(e);
      errorNotification(
        `${e.response.data.status}` + ' ' + `${e.response.data.error}`,
        `${e.response.data.message}`
      );
    }
  }

  const renderEnvironmentButton = () => {
    return (
        <>
          {subdomain === 'dashboard.ravel.systems' ? (
              <>
                <Button  onClick={() => {
                  window.open('https://dashboard-dev.ravel.systems');
                }} >Development login</Button>
              </>
          ) : (
              <>
              </>
          )}
          {subdomain === 'dashboard-dev.ravel.systems' ? (
              <>
                <Button  onClick={() => {
                  window.open('https://dashboard.ravel.systems');
                }} >Production login </Button>
              </>
          ) : (
              <>
              </>
          )}
          {subdomain === 'localhost:3000' ? (
              <>
                <Button  onClick={() => {
                  window.open('https://dashboard.ravel.systems');
                }} >Production login </Button>
              </>
          ) : (
              <>
              </>
          )}
        </>
    )
  }

  return (
    <>
      <DefaultPageLayoutOpen>
        <div style={
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }
        } >
          <Card size="big" title="Log in" style={{
            width: 325
          }}>



                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={handleSubmit}
                >
                  {/*<Form.Item label="">*/}
                  {/*  <Select*/}
                  {/*      showSearch*/}
                  {/*      placeholder="Select environment"*/}
                  {/*      optionFilterProp="children"*/}
                  {/*      onChange={onChange}*/}
                  {/*      onSearch={onSearch}*/}
                  {/*      filterOption={(input, option) =>*/}
                  {/*          option.children*/}
                  {/*              .toLowerCase()*/}
                  {/*              .indexOf(input.toLowerCase()) >= 0*/}
                  {/*      }*/}
                  {/*  >*/}
                  {/*    <Option value="http://localhost:8080/">local</Option>*/}
                  {/*    <Option value="https://dev.ravel.systems/">*/}
                  {/*      dev.ravel [dev]*/}
                  {/*    </Option>*/}
                  {/*    <Option value="https://live.ravel.systems/">*/}
                  {/*      app.ravel [prod]*/}
                  {/*    </Option>*/}
                  {/*  </Select>*/}
                  {/*</Form.Item>*/}
                  <Form.Item
                      type="email"
                      id="email-field"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email!',
                        },
                      ]}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item
                      type="password"
                      id="password-field"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Password!',
                        },
                      ]}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    {renderEnvironmentButton()}
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                      Log in
                    </Button>

                  </Form.Item>
                </Form>
          </Card>
        </div>
      </DefaultPageLayoutOpen>
    </>
  );
}
export default Login;


