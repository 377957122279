import React, {useState} from 'react';
import {Button, Popconfirm} from 'antd';
import { deleteAccount} from '../../api/services/userService';
import {errorNotification, infoNotification} from '../Notification';

export const DeleteAccount = ({user, callBack}) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopConfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    unLock().finally(() => {
      setConfirmLoading(true);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const unLock = async () => {
    try {
      const  response  = await deleteAccount(user.userUUID);
      if(response.status === 202) {
        infoNotification('Account deleted successfully');
        callBack();
      }
      if(response.status === 405) {
        setConfirmLoading(true);
        errorNotification('Account is not deleted');
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };




  return (
<>
  <Popconfirm
      title="Delete account"
      visible={visible}
      onConfirm={handleOk}
      okButtonProps={{
        loading: confirmLoading,
      }}
      onCancel={handleCancel}
  >
    <Button type="primary" danger onClick={showPopConfirm}>
      Delete
    </Button>
  </Popconfirm>
</>
  );
}
