import {errorNotification, infoNotification} from '../Notification';
import {Switch} from 'antd';
import React, {useState} from 'react';
import {setActiveStatusEnvironment} from '../../api/services/creatorServices';

export const DeActivateEnvironment = ({environment, callBack}) => {

  const [loading, setLoading] = useState(false);

  const unLock = async (boolean) => {
    try {
      const  responseData  = await setActiveStatusEnvironment(environment.environmentUuid, boolean);
      if(responseData.status === 204) {
        infoNotification('Environment updated successfully');

        callBack();
        setLoading(false);
      }
      if(responseData.status === 404) {
        errorNotification('Could not be updated');
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  function handleOnChange(checked) {
    setLoading(true);
    unLock(checked);
  }


  return (

      <>
        <Switch loading={loading} onChange={handleOnChange} checked={environment.active} />
      </>
  );
}
