import { Empty, Card, Badge, Tag, Modal } from 'antd';
import { useEffect, useState } from 'react';
import {
  getPermissions,
  getRolesWithPermissions,
  removeRoleFromPermission,
} from '../../api/services/appAuthServices';
import Meta from 'antd/es/card/Meta';
import styled from 'styled-components';
import { errorNotification, successNotification } from '../Notification';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ModalAssignPermissionToRole from './ModalAssignPermissionToRole';

function AppAuthTable() {
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);

  async function getData() {
    try {
      const response = await getRolesWithPermissions();
      if (response.status === 200) {
        setData(response.data);
        successNotification('Successfully fetched data');
        setFetching(false);
      } else {
        setData([]);
        setFetching(false);
        errorNotification('Failed to fetch roles and permissions');
      }
    } catch (e) {
      setFetching(false);
      console.error(e);
    }
  }

  const fetchPermissions = () => {
    getPermissions()
      .then((response) => {
        if (response.status === 409) {
          errorNotification(
            response.data.status + ' ' + response.data.error,
            response.data.message,
            'bottomRight'
          );
        }
        if (response.status === 200) {
          setPermissions(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        err.response.json().then((res) => {
          console.log(res);
          errorNotification(
            'There was an issue',
            `${res.message} [${res.status}] [${res.error}]`,
            'bottomRight'
          );
        });
      });
  };

  useEffect(() => {
    getData();
    fetchPermissions();
  }, []);

  const color = (tag) => {
    let color = tag;
    if (tag === 'ORGANIZATION') {
      color = 'green';
    }
    if (tag === 'SPACE') {
      color = 'blue';
    }
    return color;
  };

  const addPermission = () => {
    Modal.confirm({
      title: 'Add Permission',
      icon: <ExclamationCircleOutlined />,
      content: {},
      okText: 'Ok',
      cancelText: 'Cancel',
    });
  };

  const removePermissionFromRole = (appRoleName, appPermissionName) => {
    removeRoleFromPermission(appPermissionName, appRoleName)
      .then((response) => {
        if (response.status === 409) {
          errorNotification(
            response.data.status + ' ' + response.data.error,
            response.data.message,
            'bottomRight'
          );
        }
        if (response.status === 204) {
          successNotification(
            'Permission removed role',
            `${appPermissionName} was removed from ${appRoleName}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        err.response.json().then((res) => {
          console.log(res);
          errorNotification(
            'There was an issue',
            `${res.message} [${res.status}] [${res.error}]`,
            'bottomRight'
          );
        });
      });
  };

  const renderCards = () => {
    if (fetching) {
      return (
        <>
          <Card style={{ width: 300, marginTop: 16 }} loading={fetching}></Card>
        </>
      );
    }
    if (data.length <= 0) {
      return (
        <>
          <Empty />
        </>
      );
    }
    return (
      <>
        {data?.map((item, index) => {
          return (
            <Badge.Ribbon color={color(item.purpose)} text={`${item.purpose}`}>
              <Card
                style={{
                  // minHeight: 200,
                  width: 300,
                  marginTop: 16,
                }}
                key={index}
              >
                <Meta title={item.appRoleName} />
                <ContentCard>
                  <p>{item.description} </p>

                  <PermissionsContainer>
                    {item.appPermissions?.map((permission, index) => {
                      return (
                        <>
                          <Tag
                            closable
                            onClose={() => {
                              removePermissionFromRole(
                                item.appRoleName,
                                permission.appPermissionName
                              );
                            }}
                            key={index}
                          >
                            {permission.appPermissionName}
                          </Tag>
                        </>
                      );
                    })}
                  </PermissionsContainer>
                </ContentCard>
                <FooterCard
                  style={{
                    background: '#fff',
                  }}
                >
                  <ModalAssignPermissionToRole
                    permissions={permissions}
                    refreshRoles={getData}
                    selectedRole={item.appRoleName}
                  />
                </FooterCard>
              </Card>
            </Badge.Ribbon>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <ContentWrapper>{renderCards()}</ContentWrapper>
      </div>
    </>
  );
}

export default AppAuthTable;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const PermissionsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 4px 4px;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: 45px;
`;

const FooterCard = styled.div`
  margin-top: 15px;
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  //gap: 4px 4px;
  //flex-wrap: wrap;
  //justify-content: end;
`;

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  margin-top: 15px;
`;
