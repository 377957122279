import {
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Row,
  Switch,
  Tag,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getAllModules } from '../../api/services/moduleServices';
import { errorNotification, successNotification } from '../Notification';
import { getTokenGatedInformation } from '../../api/services/SpacesGateServices';
import { ModalSetTokenGatedAccess } from './ModalSetTokenGatedAccess';
import DeleteSpace from './DeleteSpace';
import { getSpaceProWithUuid } from '../../api/services/spacesProServices';
import {UpdateMaxOnlineUsers} from './UpdateMaxOnlineUsers';

const { Paragraph, Text, Link } = Typography;

function SpaceProDetailsDrawer({
  showDetailsDrawer,
  setShowDetailsDrawer,
  spaceUuid,
  refreshOverview,
}) {
  const [data, setData] = useState({});
  const [tokenGateData, setTokenGateData] = useState({});
  const [fetching, setFetching] = useState(true);

  async function getTokenGatedAccessInfo() {
    try {
      const response = await getTokenGatedInformation(spaceUuid);
      if (response.status === 200) {
        setTokenGateData(response.data);
      } else {
        setTokenGateData([]);
        setFetching(false);
        errorNotification('Failed to retrieve token gated info');
      }
    } catch (e) {
      setFetching(false);
      console.error(e);
    }
  }

  function onClose2() {
    setShowDetailsDrawer(false);
    refreshOverview();
  }

  async function getSpaceDetails() {
    try {
      const response = await getSpaceProWithUuid(spaceUuid);
      if (response.status === 200) {
        setData(response.data);
        // successNotification('Successfully fetched space details');
        // console.log('space details', response.data);
      } else {
        setTokenGateData([]);
        setFetching(false);
        errorNotification('Failed to retrieve space details');
      }
    } catch (e) {
      setFetching(false);
      console.error(e);
    }
  }

  useEffect(() => {
    if (spaceUuid && showDetailsDrawer) {
      getTokenGatedAccessInfo()
        .then(() => getSpaceDetails())
        .finally(() => setFetching(false));
    }
  }, [showDetailsDrawer]);

  function refreshTokenGated() {
    setFetching(true);
    getTokenGatedAccessInfo();
  }

  function closeAndRefresh() {
    refreshOverview();
    setShowDetailsDrawer(false);
  }

  const renderDetails = () => {
    if (fetching) {
      return <></>;
    }
    return (
      <>
        <Descriptions
          title="Space details"
          bordered
          extra={
            <>
              <DeleteSpace
                spaceUuid={data.spaceUuid}
                fetchData={closeAndRefresh}
              />
            </>
          }
        >
          <Descriptions.Item label="Host tools
          (ONLY APP.RAVEL.WORLD)" span={3}>

            <Link href={`https://app.ravel.world/socket/admin/${data.sessionSpaceId}`} target="_blank">{`https://app.ravel.world/socket/admin/${data.sessionSpaceId}`}</Link>
          </Descriptions.Item>
          <Descriptions.Item label="Name" span={3}>
            {data.spaceName}
          </Descriptions.Item>
          <Descriptions.Item label="Description" span={3}>
            {data.description}
          </Descriptions.Item>
          <Descriptions.Item label="Type" span={3}>
            {data.spaceType}
          </Descriptions.Item>
          <Descriptions.Item label="UUID" span={3}>
            <Paragraph copyable>{data.spaceUuid}</Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Session Id / Photon Id" span={3}>
            <Paragraph copyable>{data.sessionSpaceId}</Paragraph>
          </Descriptions.Item>

          <Descriptions.Item label="Is space code protected?" span={3}>
            <Switch disabled={true} checked={data.codeProtected} />
          </Descriptions.Item>

          <Descriptions.Item label="Default role(s)" span={3}>
            {data.defaultSpaceRoles.map((role, index) => (
              <>
                <Tag id={index}> {role.appRoleName} </Tag>
              </>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="Your personal role(s)" span={3}>
            {data.userSpaceRoles.map((role, index) => (
              <>
                <Tag id={index}> {role.appRoleName} </Tag>
              </>
            ))}
          </Descriptions.Item>

          <Descriptions.Item label="Owner of space" span={3}>
            {data.spaceOwner ? data.spaceOwner?.ownerUuid : 'No owner'}
          </Descriptions.Item>
          <Descriptions.Item label="Owner type" span={3}>
            {data.spaceOwner ? data.spaceOwner?.ownerType : 'No owner'}
          </Descriptions.Item>
          <Descriptions.Item label="Owner custom modules" span={3}>
            {data.spaceOwner ? (
              <>
                {data.spaceOwner?.customModules?.map((module, index) => (
                  <>
                    <Tag>{module.name}</Tag>
                  </>
                ))}
              </>
            ) : (
              'No owner'
            )}
          </Descriptions.Item>

          <Descriptions.Item label="Max online users" span={3}>
            {data.maxOnlineUsers === null
              ? (
                    <>
                      <UpdateMaxOnlineUsers
                          spaceUuid={spaceUuid}
                          currentValue={0}  />
                    </>
                )
              :
                (
                    <>
                      <UpdateMaxOnlineUsers
                          spaceUuid={spaceUuid}
                          currentValue={data.maxOnlineUsers}  />
                    </>
                )
            }
          </Descriptions.Item>

          <Descriptions.Item label="Online status" span={3}>
            {data.roomIsOnline ? (
              <>
                <Text type="danger">ONLINE</Text>
              </>
            ) : (
              <>
                <Text type="secondary">OFFLINE</Text>
              </>
            )}

            {/*{data.roomIsOnline.toString()}*/}
          </Descriptions.Item>
          <Descriptions.Item label="Current online users " span={3}>
            {data.roomOnlineUserCount}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions
          title="Environment details"
          bordered
          // extra={
          //   <>
          //     <ModalSetTokenGatedAccess
          //         fetchData={refreshTokenGated}
          //         spaceUuid={data.spaceUuid}
          //     />
          //   </>
          // }
        >
          <Descriptions.Item label="Environment name" span={3}>
            {data.environmentPro.name}
          </Descriptions.Item>
          <Descriptions.Item label="Short summary" span={3}>
            {data.environmentPro?.shortSummary}
          </Descriptions.Item>
          <Descriptions.Item label="Long summary" span={3}>
            {data.environmentPro?.longSummary}
          </Descriptions.Item>
          <Descriptions.Item label="Environment UUID" span={3}>
            <Paragraph copyable>
              {data.environmentPro.environmentUuid}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Creator name" span={3}>
            {data.environmentPro.creator?.creatorName}
          </Descriptions.Item>
          <Descriptions.Item label="Creator bio" span={3}>
            {data.environmentPro.creator?.creatorBio}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions
          title="Token Gated Access"
          bordered
          extra={
            <>
              <ModalSetTokenGatedAccess
                fetchData={refreshTokenGated}
                spaceUuid={data.spaceUuid}
              />
            </>
          }
        >
          {tokenGateData.chain && (
            <>
              <Descriptions.Item label="Token name" span={3}>
                {tokenGateData.tokenName}
              </Descriptions.Item>

              <Descriptions.Item label="Token ID" span={3}>
                {tokenGateData.tokenId}
              </Descriptions.Item>

              <Descriptions.Item label="Minimum Quantity" span={3}>
                {tokenGateData.minimumQuantity}
              </Descriptions.Item>

              <Descriptions.Item label="Blockchain" span={3}>
                {tokenGateData.chain}
              </Descriptions.Item>

              <Descriptions.Item label="Contract address" span={3}>
                {tokenGateData.contractAddress}
              </Descriptions.Item>

              <Descriptions.Item label="Contract type" span={3}>
                {tokenGateData.contractType}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      </>
    );
  };

  return (
    <Drawer
      width={740}
      placement="right"
      closable={true}
      onClose={() => onClose2()}
      visible={showDetailsDrawer}
    >
      {showDetailsDrawer && <>{renderDetails()}</>}
    </Drawer>
  );
}

export default SpaceProDetailsDrawer;
