import {Menu} from 'antd';
import {NavLink} from 'react-router-dom';
import {Header} from 'antd/lib/layout/layout';
import React from 'react';


export const ContentLayoutEvents = ({children}) => {
  return (
      <>
        <Header
            style={{
              background: '#fff',
            }}
        >
          <Menu
              theme="light"
              mode="horizontal"
          >
            <Menu.Item
                key="SubNav-01"
            >
              Events
              <NavLink
                  to="/events" ></NavLink>
            </Menu.Item>

          </Menu>
        </Header>
        <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
        >
          {children}
        </div>
      </>
  )
}


