import {useCallback, useContext, useEffect, useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import {AuthContext} from '../../context/AuthContext';
import {
  getSessionDetails,
  getSpacePro, getWebGLBuildConfig,
} from '../../api/services/spacesProServices';
// import {WebGL3} from './WebGL3';


function WebGLViewer() {
  const [title, setTitle] = useState("");

  const envId = process.env.REACT_APP_SYSTEMS_ENV_ID;
  const apiBaseUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL + "/";
  const photonMode = process.env.REACT_APP_SYSTEMS_PHOTON_MODE;
  const { spaceId } = useParams();
  const [spacePro, setSpacePo] = useState({});
  const [room, setRoom] = useState({});
  const { user } = useContext(AuthContext);
  const [apiDone, setApiDon] = useState(false);
  const navigate = useNavigate();
  const [buildUrl, setBuildUrl] = useState('');
  const [streamingAssetsUrl, setStreamingAssetsUrl] = useState('');



  const fetchSpacePro = useCallback(async () => {
    try {
      const response = await getSpacePro(spaceId);
      if (response.data) {
        navigate("/spaces");
        setSpacePo(response.data);
        setTitle(response.data.spaceName);
      } else {
        // errorNotification("InputFieldError loading space");
        navigate("/spaces");
      }
    } catch (error) {
      // errorNotification("InputFieldError loading space");
      navigate("/spaces");
    }
  }, []);

  const fetchRoom = useCallback(async () => {
    try {
      const response = await getSessionDetails(user.userUUID, spaceId);
      if (response.data) {
        setRoom(response.data);
      } else {
        // errorNotification("InputFieldError loading space");
        navigate("/spaces");
      }
    } catch (error) {
      // errorNotification("InputFieldError loading space");
      navigate("/spaces");
    }
  }, []);

  const fetchWebGLConfig = useCallback(async () => {
    try {
      const response = await getWebGLBuildConfig(envId);
      if (response.data) {
        setBuildUrl(response.data.build.buildBaseUrl);
        const url = response.data.build.buildBaseUrl.substring(0, response.data.build.buildBaseUrl.lastIndexOf("/"));
        const url2 = response.data.build.buildBaseUrl.substring(0, url.lastIndexOf("/"));
        setStreamingAssetsUrl(url2);

      } else {
        // errorNotification("InputFieldError loading space");
        // navigate("/spaces");
      }
    } catch (error) {
      // errorNotification("InputFieldError loading space");
      navigate("/spaces");
    }
  }, []);


  useEffect(() => {
    fetchSpacePro().then(
        () => fetchRoom().then(() => fetchWebGLConfig()).finally(() => setApiDon(true))
    )
  }, []);

  return (
      <>
        {/*<PageDetails title={title} description={spacePro.description} meta={"hellow"} />*/}
        {/*<BrowserView>*/}
        {/*  {apiDone === true && (*/}
        {/*      <WebGL3 spacePro={spacePro} room={room} buildUrl={buildUrl} streamingAssetsUrl={streamingAssetsUrl} agoraEnabled={true} apiBaseUrl={apiBaseUrl} photonMode={photonMode} ></WebGL3>*/}

        {/*  )}*/}
        {/*</BrowserView>*/}
        {/*<MobileView>*/}
        {/*  <ModalMobileOnly />*/}
        {/*</MobileView>*/}

      </>
  );
}

export default WebGLViewer;


