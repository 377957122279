import {deleteSpacePro} from '../../api/services/spacesProServices';
import React, { useState } from 'react';
import {Button, Popconfirm} from 'antd';
import {infoNotification} from '../Notification';


const DeleteSpace = ({spaceUuid, fetchData}) => {


  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    delSpacePro()
  };

  const handleCancel = () => {
    setVisible(false);
  };



  const delSpacePro = async () => {
    try {
      const  responseData  = await deleteSpacePro(spaceUuid);
      if(responseData.status === 204) {
        infoNotification('Space deleted successfully');
        setVisible(false);
        setConfirmLoading(false);
        fetchData();
      } else
      {
        setVisible(false);
        setConfirmLoading(false);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };


  return(
      <>
        <Popconfirm
            title="Are you sure to delete the space?"
            visible={visible}
            onConfirm={handleOk}
            okText="Yes"
            cancelText="no"
            okButtonProps={{
              loading: confirmLoading,
            }}
            onCancel={handleCancel}
        >
          <Button type="primary" danger onClick={showPopconfirm}>
            Delete
          </Button>
        </Popconfirm>
      </>
  )
}

export default DeleteSpace;
