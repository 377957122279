import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  getAllSpacesAsRavelAdmin,
  getSpaces,
} from '../../api/services/spacesProServices';
import { Header } from 'antd/es/layout/layout';
import { Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import NewSpacePublic from './NewSpacePublic';
import { SpacesCard } from './SpacesCard';

function SpaceProTable({
  spaceType,
  organizationId,
  eventId,
  newSpaceDisabled,
}) {
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(true);

  const fetchData = () =>
    getSpaces(spaceType, organizationId, eventId)
      .then((response) => {
        setData(response.data);
        setFetching(false);
        setDataUpdated(false);
      })
      .catch((err) => {})
      .finally(() => setFetching(false));

  useEffect(() => {
    if(dataUpdated){
      setFetching(true);
      fetchData();
    }
  }, [dataUpdated]);

  return (
    <>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        {newSpaceDisabled ? (
          <>
            <Header
              style={{
                background: '#fff',
              }}
            >
              <NewSpacePublic
                showDrawer={showDrawer}
                setShowDrawer={setShowDrawer}
                fetchData={fetchData}
              />
              <Button
                onClick={() => setShowDrawer(!showDrawer)}
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                size="small"
              >
                Add public space
              </Button>
            </Header>
          </>
        ) : (
          <></>
        )}
        <ContentWrapper>
          <SpacesCard
              data={data}
              fetching={fetching}
              setDataUpdated={setDataUpdated}
          />
        </ContentWrapper>
      </div>
    </>
  );
}

export default SpaceProTable;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const PermissionsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 4px 4px;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: 45px;
`;

const FooterCard = styled.div`
  margin-top: 15px;
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  //gap: 4px 4px;
  //flex-wrap: wrap;
  //justify-content: end;
`;

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  margin-top: 15px;
`;
