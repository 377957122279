import client from '../client';
import Cookies from 'js-cookie';
import axios from 'axios';

const token = Cookies.get('token');
const envUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
const source = axios.CancelToken.source();


const getRoles = async () => {
  try {
    const { data } = await client.get(envUrl + '/api/' + 'v1/appauth/roles', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getPermissions = async () => {
  try {
    const  response  = await client.get(
      envUrl + '/api/' + 'v1/appauth/permissions',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getRolesWithPermissions = async () => {
  try {
    const response = await client.get(
      envUrl + '/api/' + 'v1/appauth/roles/permissions',
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const addPermission = async (payload) => {
  try {
    const response = await client.post(
        `${envUrl}/api/v1/admin/appauth/permissions`, payload
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const addAssignRoleToPermission = async (permission, role) => {
  try {
    const response = await client.post(
        envUrl +  `/api/v1/admin/appauth/permissions/${permission}`,
        {
          appRoleName : role
        },
        {
         cancelToken: source.token
        }
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const removeRoleFromPermission = async (permission, role) => {
  try {
    const response = await client.delete(
        envUrl + `/api/v1/admin/appauth/permissions/${permission}?appRoleName=${role}`,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export { getRoles, getRolesWithPermissions, getPermissions };
