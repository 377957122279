import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
  Badge,
  Breadcrumb,
  Button,
  Descriptions,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { errorNotification, successNotification } from '../Notification';
import {
  approveSubmission,
  getAllSubmissions,
  getSubmission,
} from '../../api/services/creatorServices';
import {Link, useNavigate, useParams} from 'react-router-dom';
import DefaultPageLayout from '../DefaultPageLayout';
import Text from 'antd/es/typography/Text';
import {getSpecificBuild} from '../../api/services/devTools';
import {ConvertDate} from '../../helpers/ConvertDate';
import AddUserToOrgDrawer from '../organizations/AddUserToOrgDrawer';
import DrawerPromoteBuildToEnvironment from './DrawerPromoteBuildToEnviornment';

function BuildDetail() {
  const { buildUuid } = useParams();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);


  const [visible, setVisible] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getSpecificBuild(buildUuid);
      if (response.status === 200) {
        setData(response.data);
        setFetching(false);
      } else {
        setData({});
        setFetching(false);
      }
    } catch (e) {
      errorNotification(
        'Error',
        `an error occurred while fetching data: ${e.message}`
      );
    }
  }, []);

  function onClick(bool) {
    // approveSubmission(data.environment.environmentUuid, submissionUuid, bool)
    //   .then(() => {
    //     successNotification('Success', 'Submission processed successfully');
    //     fetchData();
    //   })
    //   .catch((e) => {
    //     errorNotification(
    //       'Error',
    //       `an error occurred while approving submission: ${e.message}`
    //     );
    //   });
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderData = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <Descriptions
          title="Submission details"
          bordered
          extra={
            <>
              <DrawerPromoteBuildToEnvironment
                  build={data}
                  showDrawer={showDrawer}
                  setShowDrawer={setShowDrawer}
                  fetchData={fetchData}
              />

              <Popconfirm
                placement="topRight"
                title={`Are you sure to promote this build to an environment?`}
                onConfirm={() => setShowDrawer(true)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Promote build</Button>
              </Popconfirm>
            </>
          }
        >
          <Descriptions.Item label="Build Uuid" span={3}>
            {data.buildUuid}
          </Descriptions.Item>
          <Descriptions.Item label="hashId" span={3}>
            {data.hashId}
          </Descriptions.Item>
          <Descriptions.Item label="uploadType" span={3}>
            {data.uploadType}
          </Descriptions.Item>
          <Descriptions.Item label="buildBaseUrl" span={3}>
            {data.buildBaseUrl}
          </Descriptions.Item>
          <Descriptions.Item label="Created at" span={3}>
            {ConvertDate(data.createdAt)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated at" span={3}>
            {data.updatedAt ? ConvertDate(data.updatedAt) : '-'}
            {ConvertDate(data.updatedAt)}
          </Descriptions.Item>

          <Descriptions.Item label="Current deployed environments:" span={3}>
            {data.environments.length > 0 ? (
                    <>
                      <>
                        {data.environments.map((tag) => {
                          let color = tag;
                          if (tag.environmentName === 'production') {
                            color = 'green';
                          }
                          if (tag.environmentName === 'development') {
                            color = 'blue';
                          }
                          if (tag.environmentName === 'demo') {
                            color = 'orange';
                          }
                          return (
                              <Tag color={color} key={tag}>
                                {tag.environmentName}
                              </Tag>
                          );
                        })}
                      </>
                    </>
                )
                :
                (
                    <>
                     Not deployed to any environment
                    </>
                )

            }
          </Descriptions.Item>
          {/*{data.environments.length < 0 && (*/}
          {/*    <></>*/}
          {/*)}*/}
          {/*{data.environments }*/}

          <Descriptions.Item label="ZIP file" span={3}>
            <Text code copyable >
              {data.metadataArchive.archiveUrlS3}
            </Text>{' '}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  return (
    <>
      <DefaultPageLayout>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Management Console</Breadcrumb.Item>
          <Breadcrumb.Item>Dev tools</Breadcrumb.Item>
          <Breadcrumb.Item>Builds</Breadcrumb.Item>
          <Breadcrumb.Item>Details</Breadcrumb.Item>
        </Breadcrumb>
        {renderData()}
      </DefaultPageLayout>
    </>
  );
}

export default BuildDetail;
