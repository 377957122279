import React, {useState} from 'react';
import {Button, Popconfirm, Switch} from 'antd';
import {deActivateAccount, unLockAccount} from '../../api/services/userService';
import {infoNotification} from '../Notification';

export const DeActivateAccount = ({user, callBack}) => {


  const unLock = async (boolean) => {
    try {
      const  responseData  = await deActivateAccount(user.userUUID, boolean);
      if(responseData.status === 202) {
        infoNotification('Account deactivated successfully');
        callBack();
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  function handleOnChange(checked) {
    unLock(checked);
  }


  return (

<>
    <Switch  onChange={handleOnChange} checked={user.active} />
</>
  );
}
