import DefaultPageLayout from '../../components/DefaultPageLayout';

import {ContentLayoutEvents} from '../../components/events/ContentLayoutEvents';
import {EventsTable} from '../../components/events/EventsTable';

export const Events = () => {

  return (
      <DefaultPageLayout>
        <ContentLayoutEvents>
          <EventsTable/>
        </ContentLayoutEvents>
      </DefaultPageLayout>
  );
}
