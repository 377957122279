import DefaultPageLayout from '../../components/DefaultPageLayout';
import ContentLayoutModules
  from '../../components/modules/ContentLayoutModules';
import ModulesOverview from '../../components/modules/ModulesOverview';

function Modules() {

  return (
    <DefaultPageLayout>
      <ContentLayoutModules>
        <ModulesOverview/>
      </ContentLayoutModules>
    </DefaultPageLayout>
  );
}
export default Modules;
