import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
  Badge,
  Breadcrumb,
  Button,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { errorNotification, successNotification } from '../Notification';
import {
  getAllAccountRequests,
  getAllSubmissions,
} from '../../api/services/creatorServices';
import { useNavigate } from 'react-router-dom';
import ContentLayoutUsers from '../users/ContentLayoutUsers';

function CreatorsAccountsRequestsSection() {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);


  const showDrawer = (item) => {
    navigate('/creators/accounts/requests/' + item);
  };


  const columns = (fetchData) => [
    {
      title: 'submissionUuid',
      dataIndex: 'submissionUuid',
      key: 'submissionUuid',
      ellipsis: {
        showTitle: false,
      },
      render: (submissionUuid) => (
        <Tooltip placement="topLeft" title={submissionUuid}>
          {submissionUuid}
        </Tooltip>
      ),
    },

    {
      title: 'Creator name',
      dataIndex: 'creatorName',
      key: 'creatorName',
      // render: (item) => item.name,
    },
    {
      title: 'Creator email',
      dataIndex: 'creatorEmail',
      key: 'creatorEmail',
      // render: (item) => item.name,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (item) => (
        <>
          <a
            onClick={() => showDrawer(item.submissionUuid)}
            key={`a-${item.submissionUuid}`}
          >
            Review submission
          </a>
        </>
      ),
    },
  ];

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllAccountRequests();
      if (response.status === 200) {
        if (response.data.length > 0) {
          const filteredData = response.data.filter(
            (item) => item.active === true
          );
          setData(filteredData);
          setFetching(false);
        } else {
          setData([]);
          setFetching(false);
        }
      } else {
        setData([]);
        setFetching(false);
      }
    } catch (e) {
      errorNotification(
        'Error',
        `an error occurred while fetching data: ${e.message}`
      );
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderData = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <Table
          dataSource={data}
          columns={columns(fetchData)}
          bordered
          title={() => (
            <>
              <Tag>Number of open submissions</Tag>
              <Badge count={data.length} className="site-badge-count-4" />
            </>
          )}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 500 }}
          rowKey={(data) => data.submissionUuid}
        />
      </>
    );
  };

  return (
    <>
<ContentLayoutUsers>
  {renderData()}

</ContentLayoutUsers>
    </>
  );
}

export default CreatorsAccountsRequestsSection;
