import {Button, Checkbox, Form, Input, Modal, Select} from 'antd';
import React, { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import {addPermission} from '../../api/services/appAuthServices';
import {errorNotification, successNotification} from '../Notification';
import {createNewEvent} from '../../api/services/EventServices';
import {Option} from 'antd/es/mentions';
import {setTokenGatedAccess} from '../../api/services/SpacesGateServices';

export const ModalSetTokenGatedAccess = ({spaceUuid, fetchData}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(false);
    setVisible(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    submitToApi(values);
  }

  const handleCancel = () => {
    setLoading(false);
    setVisible(false);
  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const submitToApi = (values) => {
    setTokenGatedAccess(spaceUuid, values)
        .then((response) => {
          if(response.status === 409) {
            errorNotification(response.data.status + " " + response.data.error,
                response.data.message, 'bottomRight');
            setLoading(false);
          }
            if(response.status === 201){
              handleOk();
            setLoading(false);
            successNotification(
                'Event successfully created',
                `${values.eventName} was added to the system`
            );
            fetchData();
          }
        }
        )
        .catch((err) => {
          console.log(err);
          err.response.json().then((res) => {
            setLoading(false);
            console.log(res);
            errorNotification(
              'There was an issue',
              `${res.message} [${res.status}] [${res.error}]`,
              'bottomRight'
            );
          });
        })
  }



  const renderForm = () => {
    return (
        <>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                label="tokenName"
                name="tokenName"
                rules={[
                  {
                    required: true,
                    message: 'Please provide tokenName!',
                  },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Token ID"
                name="tokenId"
                rules={[
                  {
                    required: true,
                    message: 'Please provide token ID!',
                  },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Minimum Quantity"
                name="minimumQuantity"
                rules={[
                  {
                    required: true,
                    message: 'Please provide token ID!',
                  },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Purchasing Link"
                name="purchasingLink"
                rules={[
                  {
                    required: true,
                    message: 'Please provide token ID!',
                  },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="Blockchain"
                name="chain"
                rules={[
                  {
                    required: true,
                    message: 'Please provide blockchain!',
                  },
                ]}

            >
              <Select
                  placeholder="Select blockchain"
                  allowClear
              >
                <Option value="ETHEREUM">ETHEREUM</Option>
              </Select>
            </Form.Item>
            <Form.Item
                label="Contract Type"
                name="contractType"
                rules={[
                  {
                    required: true,
                    message: 'Please provide contract type!',
                  },
                ]}

            >
              <Select
                  placeholder="Select contract type"
                  allowClear
              >
                <Option value="erc1155">erc1155</Option>
              </Select>
            </Form.Item>
            <Form.Item
                label="Contract Address"
                name="contractAddress"
                rules={[
                  {
                    required: true,
                    message: 'Please provide token ID!',
                  },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
            >
              <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}

              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
    )
  }

  return (
      <>
        <Button type="primary" onClick={showModal}>
          Configure Token Gate
        </Button>
        <Modal
            visible={visible}
            title="Create new event"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              // <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
              //   Add
              // </Button>,
              <Button
                  // key="link"
                  // href="https://google.com"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
              >
                Done
              </Button>,
            ]}
        >
          {renderForm()}

        </Modal>
      </>
  );
};


