import {Menu} from 'antd';
import {NavLink} from 'react-router-dom';
import {Header} from 'antd/lib/layout/layout';
import React from 'react';


const ContentLayoutApiDocumentation = ({children}) => {
  return (
      <>
        <Header
            style={{
              // display: 'flex',
              background: '#fff',
              // marginTop: '15',
              alignContent: 'center',
              // alignItems: 'center',
              // justifyContent: 'center',
            }}
        >
          <Menu
              theme="light"
              mode="horizontal"
          >
            <Menu.Item
                key="SubNav-01"
            >
              API documentation
              <NavLink
                  to="/dev-tools/builds" ></NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
        >
          {children}
        </div>
      </>
  )
}

export default ContentLayoutApiDocumentation;
