import { Table, Spin, Empty, Button, Tooltip, Breadcrumb } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { errorNotification } from '../Notification';
import NewOrganizationDrawerForm from './NewOrganizationDrawerForm';
import Cookies from 'js-cookie';
import ContentLayoutOrganizations from './ContentLayoutOrganizations';
import {Header} from 'antd/es/layout/layout';

function OrganizationsList() {
  const [users, setData] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

  const columns = (fetchDatas) => [
    {
      title: 'organizationName',
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: (a, b) => a.organizationName.length - b.organizationName.length,
      sortDirections: ['descend'],
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      sortDirections: ['descend'],
    },
    {
      title: 'organizationId',
      // width: 335,
      dataIndex: 'organizationId',
      key: 'organizationId',
      ellipsis: {
        showTitle: false,
      },
      render: (organizationId) => (
        <Tooltip placement="topLeft" title={organizationId}>
          {organizationId}
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    const source = axios.CancelToken.source();

    async function fetchData() {
      // const apiUrl = Cookies.get('envUrl');
      const token = Cookies.get('token');

      try {
        const result = await axios.get(apiUrl + '/api/v1/admin/organizations/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        setData(result.data);
        setFetching(false);
      } catch (e) {
        console.error(e);
        setFetching(false);
        errorNotification('error');
      }
    }
    fetchData();
    return function cleanup() {
      source.cancel();
    };
  }, []);

  const renderData = () => {
    if (fetching) {
      return <Spin indicator={antIcon} />;
    }
    if (users.length <= 0) {
      return (
        <>
          <Button
            onClick={() => setShowDrawer(!showDrawer)}
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="small"
          >
            Add New organization
          </Button>
          <NewOrganizationDrawerForm
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            fetchUsers={users}
          />
          <Empty />
        </>
      );
    }
    return (
      <>
        <NewOrganizationDrawerForm
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          fetchUsers={users}
        />
        <Table
          dataSource={users}
          columns={columns()}
          bordered
          title={() => (
            <>
              <Button
                onClick={() => setShowDrawer(!showDrawer)}
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                size="small"
              >
                Add new Organizations
              </Button>
            </>
          )}
          pagination={{ pageSize: 50 }}
          scroll={{ x: 1500, y: 600 }}
          rowKey={(user) => user.organizationId}
        />
      </>
    );
  };

  return (
    <>
      <ContentLayoutOrganizations>
        <Header         style={{
          background: '#fff',
        }}>

        </Header>

        {renderData()}
      </ContentLayoutOrganizations>

    </>
  );
}

export default OrganizationsList;
