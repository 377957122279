import DefaultPageLayout from '../../components/DefaultPageLayout';

import {ContentLayoutEvents} from '../../components/events/ContentLayoutEvents';
import {EventsTable} from '../../components/events/EventsTable';
import EventDetails from '../../components/events/EventDetails';

export const EventsDetails = () => {

  return (
      <DefaultPageLayout>
        <ContentLayoutEvents>
          <EventDetails/>
        </ContentLayoutEvents>
      </DefaultPageLayout>
  );
}
