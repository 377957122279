import client from '../client';
import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
const token = Cookies.get('token');

const getAllSpacesAsRavelAdmin = async () => {
  try {
    const response = await client.get(apiUrl + '/api/v1/admin/spaces/pro/all');
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getSpaces = async (spaceType, organizationUUID, eventId) => {
  try {
    const response = await client.get(apiUrl + `/api/v1/spaces/pro/spaces?spaceType=${spaceType}`
    ,
        {
          params : {
              ...(organizationUUID ? { organizationUuid: organizationUUID } : {}),
              ...(eventId ? { eventId: eventId } : {}),

          }
        }
    )
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getSpaceProOrganization = async (payload) => {
  try {
    const { data } = await client.get(
      apiUrl + '/api/v1/spaces/pro/spaces/' + payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSpaceProWithUuid = async (spaceProUuid) => {
  try {
    const response = await client.get(
        apiUrl + "/api/v1/spaces/pro/spaces/url/" + spaceProUuid
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const updateSpace = async (spaceProUuid, data) => {
  try {
    const response = await client.put(
        apiUrl + "/api/v1/spaces/pro/spaces/" + spaceProUuid,
        data
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};


const createSpaceProOrganization = async (payload) => {
  try {
    const { responseData } = await axios.post(
      apiUrl + '/api/v1/spaces/pro/organizations/',
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return responseData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const createOrganizationSpace = async (
    spaceName,
    description,
    codeProtected,
    spaceType,
    environmentUuid,
    defaultSpaceRole,
    userUuid,
    organizationName
) => {
  try {
    const response = await client.post(
        apiUrl + "/api/v1/spaces/pro/organizations/",
        {
          spaceName: spaceName,
          description: description,
          codeProtected: codeProtected,
          spaceType: spaceType,
          environmentUuid: environmentUuid,
          defaultSpaceRole: defaultSpaceRole,
          userUuid: userUuid,
          organizationName: organizationName,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const createPublicSpace = async (
    spaceName,
    description,
    codeProtected,
    spaceType,
    environmentUuid,
    defaultSpaceRole,
    userUuid,
) => {
  try {
    const response = await client.post(
        apiUrl + "/api/v1/spaces/pro/",
        {
          spaceName: spaceName,
          description: description,
          codeProtected: codeProtected,
          spaceType: spaceType,
          environmentUuid: environmentUuid,
          defaultSpaceRole: defaultSpaceRole,
          userUuid: userUuid,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

 const getSpacePro = async (spaceProId) => {
  try {
    const response = await client.get(
        apiUrl + "/api/v1/spaces/pro/sessions/" + spaceProId
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

 const getSessionDetails = async (userUuid, spaceProId) => {
  try {
    const response = await client.get(
        apiUrl + "/api/v1/spaces/pro/sessions/" + userUuid + "/" + spaceProId
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

 const getWebGLBuildConfig = async (environmentUuid) => {
  try {
    const response = await axios.get(
        `https://dev.ravel.systems/api/v1/dev-tools/config/${environmentUuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};



const updateEnvironmentSpacePro = async (spaceUuid, payload) => {
  try {
    const { responseData } = await client.put(
      apiUrl + '/api/v1/spaces/pro/' + spaceUuid,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return responseData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const deleteSpacePro = async (uuid) => {
  try {
    const response = await client.delete(
      apiUrl + `/api/v1/spaces/pro/${uuid}`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

 const createNewEventSpace = async (
     spaceName,
     description,
     codeProtected,
     spaceType,
     environmentUuid,
     defaultSpaceRole,
     userUuid,
     eventId
 ) => {
  try {
    const response = await client.post(
        `${apiUrl}/api/v1/spaces/pro/events?eventId=${eventId}`,
        {
          spaceName: spaceName,
          description: description,
          codeProtected: codeProtected,
          spaceType: spaceType,
          environmentUuid: environmentUuid,
          defaultSpaceRole: defaultSpaceRole,
          userUuid: userUuid,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};



export {
  createNewEventSpace,
  getAllSpacesAsRavelAdmin,
  getSpaceProOrganization,
  createSpaceProOrganization,
  updateEnvironmentSpacePro,
  deleteSpacePro,
    getSpaces,
    getSessionDetails,
    getWebGLBuildConfig,
    getSpacePro,
};
