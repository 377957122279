import {InputNumber} from 'antd';
import {errorNotification, infoNotification} from '../Notification';
import {updateSpace} from '../../api/services/spacesProServices';

export const UpdateMaxOnlineUsers = ({spaceUuid, currentValue, refreshData}) => {



  const onChange = async (value) => {
    const data = {
      "maxOnlineUsers": value,
    }
    const response = await updateSpace(spaceUuid, data)
    if(response.status === 202){
      infoNotification("Update successful", `Max allowed is set at ${value}`)
    }else{
      errorNotification("Update not successful, try again", `${response.message}`)
    }
  };

  const renderNumberInput = () => {

    return <InputNumber min={1} max={99} defaultValue={currentValue} onChange={onChange} />;

  }

  return renderNumberInput();

}
