import { Card, Empty, Typography, Tag} from 'antd';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {errorNotification, successNotification} from '../Notification';
import {
  getAllModules,
  removeOrganizationFromModule,
} from '../../api/services/moduleServices';
import Meta from 'antd/es/card/Meta';
import ModalAddOrganizationToModule from './ModalAddOrganizationToModule';

function ModulesOverview() {
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const { Title } = Typography;

  async function getData() {
    try {
      const response = await getAllModules();
      if (response.status === 200) {
        setData(response.data);
        successNotification('Successfully fetched data');
        setFetching(false);
      } else {
        setData([]);
        setFetching(false);
        errorNotification('Failed to fetch roles and permissions');
      }
    } catch (e) {
      setFetching(false);
      console.error(e);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const removeOrganization = (organizationId, moduleName) => {
    console.log(organizationId, moduleName);
    removeOrganizationFromModule(organizationId, moduleName)
        .then((response) => {
              if (response.status === 409) {
                errorNotification(response.data.status + ' ' + response.data.error,
                    response.data.message, 'bottomRight');
              }
              if (response.status === 204) {
                successNotification(
                    'Permission removed role',
                    `${moduleName} was removed `,
                );
              }
            },
        )
        .catch((err) => {
          err.response.json().then((res) => {

            errorNotification(
                'There was an issue',
                `${res.message} [${res.status}] [${res.error}]`,
                'bottomRight',
            );
          });
        });

  };



  const renderCards = () => {
    if (fetching) {
      return <>
        <Card style={{width: 300, marginTop: 16}} loading={fetching}>
        </Card>
      </>;
    }
    if (data.length <= 0) {
      return (
          <>
            <Empty/>
          </>
      );
    }
    return (
        <>
          {data?.map((item, index) => {
            return (
                  <Card style={{
                    // minHeight: 200,
                    width: 320, marginTop: 16,
                  }} key={index}>
                    <Meta
                        title={item.name}
                    />
                    <ContentCard>
                      <p>{item.description} </p>

                      <Title level={5} >Assigned users</Title>

                      <PermissionsContainer>
                        {!item.moduleUsers ? <Empty description={"no assigned users"} /> : item.moduleUsers?.map((user, index) => {
                        return (
                            <>
                              <>
                                <Tag
                                    color={'blue'}
                                    key={index}>{user.userId}</Tag>
                              </>
                            </>
                        )
                        })
                        }
                      </PermissionsContainer>

                      <Title level={5} >Assigned organizations</Title>
                      <PermissionsContainer>

                        {!item.moduleOrganizations ? <Empty description={"no assigned organizations"} /> :  item.moduleOrganizations?.map((organization, index) => {
                          return (
                              <>
                                <Tag
                                    closable
                                    onClose={() => {
                                      removeOrganization(organization.organizationId,
                                          item.name);
                                    }}
                                    color={'green'}
                                    key={index}>{organization.organizationId}</Tag>
                              </>

                          );
                        })}
                      </PermissionsContainer>
                    </ContentCard>
                    <FooterCard
                        style={{
                          background: '#fff',
                        }}
                    >
                      <ModalAddOrganizationToModule
                          refreshModules={getData}
                          moduleName={item.name} />

                      {/*<ModalAssignPermissionToRole permissions={permissions}*/}
                      {/*                             refreshRoles={getData}*/}
                      {/*                             selectedRole={item.appRoleName}/>*/}
                    </FooterCard>
                  </Card>
            );
          })}
        </>
    );
  };

  return (
      <>
        <div
            className="site-layout-background"
            style={{padding: 24, minHeight: 360}}
        >
          <ContentWrapper>
            {renderCards()}
          </ContentWrapper>

        </div>
      </>
  )

}

export default ModulesOverview;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const PermissionsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 4px 4px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 45px;
`;

const FooterCard = styled.div`
  margin-top: 15px;
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  //gap: 4px 4px;
  //flex-wrap: wrap;
  //justify-content: end;
`;

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  margin-top: 15px;
`;
