import styled from 'styled-components';
import Cookies from 'js-cookie';

import {Avatar, Button, Layout, Menu, PageHeader, Tag} from 'antd';
import React, {useContext, useState} from 'react';
import {Header} from 'antd/es/layout/layout';
import {
  ApartmentOutlined,
  AppstoreAddOutlined,
  BookOutlined,
  BorderOuterOutlined,
  CodepenOutlined, DotChartOutlined,

  LockOutlined,
  RocketOutlined,

  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

import {AuthContext} from '../context/AuthContext';
import Text from 'antd/es/typography/Text';
import LogoRavel from './LogoRavel';
import {NavLink} from 'react-router-dom';

const {Content, Footer, Sider} = Layout;




const DefaultPageLayout = ({children}) => {
  const {isAuth, logout, user, environment} = useContext(AuthContext);
  const environmentUrl = Cookies.get("envUrl");

  const subdomain  = /:\/\/([^\/]+)/.exec(window.location.href)[1];

  const environmentZ = () => {
    if(subdomain === "https://dashboard-dev.ravel.systems"){
      return (
          <><Text style={
            {color: '#fff',}
          } key="876533433789">Current environment: </Text>
            <Tag color="blue">Development</Tag>
          </>
      )
    }
    if(subdomain === "localhost:3000"){
      return (
          <><Text style={
            {color: '#fff',}
          } key="87f65433789">Current environment: </Text>
            <Tag color="orange">Local</Tag>
          </>
      )

    }
    if(subdomain === "https://dashboard.ravel.systems"){
      return (
          <><Text style={
            {color: '#fff',}
          } key="87654337289">Current environment: </Text>
            <Tag color="green">Production</Tag>
          </>
      )
    }
  }


  function renderItems () {
    if(!user){
      return (
          <>
          </>
      )
    }
    if(user.authorities.includes("dev:access")){
      return(
          <>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}
                key="MainMenu-01"
                icon={<DotChartOutlined />}
            >
              Stats & analytics
              <NavLink to="/stats" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}
                key="MainMenu-01"
                icon={<UserOutlined />}
            >
              User management
              <NavLink to="/users" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}
                key="MainMenu-02"
                icon={<ApartmentOutlined />}
            >
              Organization management
              <NavLink to="/organizations" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}
                key="MainMenu-03"
                icon={<BorderOuterOutlined />}
            >
              Environments management
              <NavLink to="/environments" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}

                key="MainMenu-04"
                icon={<CodepenOutlined />}
            >
              Spaces management
              <NavLink to="/spaces" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}

                key="MainMenu-05"
            >
              Photon analytics
              <NavLink to="/photon/analytics" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}
                key="MainMenu-0584hhs"
                icon={<LockOutlined />}
            >
              Roles & Permissions
              <NavLink to="/applicationRoles" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}
                key="MainMenu-06"
                icon={<RocketOutlined />}
            >
              Dev tools
              <NavLink to="/dev-tools/builds" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}
                key="MainMenu-078"
                icon={<AppstoreAddOutlined />}
            >
              Modules
              <NavLink to="/modules" ></NavLink>
            </Menu.Item>
            <Menu.Item
                style={{
                  background: '#000000',
                  color: '#fff',
                  // hover: '#c1ff3f',
                }}
                key="MainMenu-098"
                icon={<AppstoreAddOutlined />}
            >
              Events
              <NavLink to="/events" ></NavLink>
            </Menu.Item>
          </>
      )
    }
  }

  return (
      <>
        <Layout
            hasSider
        >
          <Sider
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
                color: '#fff',
                background: '#000000',
              }}
          >
            <ContainerLogo>
              <LogoRavel/>

            </ContainerLogo>

            <Menu
                style={{
                  background: '#000000'
                  ,
                }}
                heme="dark"
                mode="inline"
            >
              {renderItems()}

              <Menu.Item
                  style={{
                    background: '#000000',
                    color: '#fff',
                    // hover: '#c1ff3f',
                  }}
                  key="MainMenu-api-doc"
                  icon={<BookOutlined />}
              >
                API documentation
                <NavLink to="/api-docs" ></NavLink>
              </Menu.Item>
            </Menu>


          </Sider>
          <Layout
              className="site-layout"
              style={{
                marginLeft: 200,
              }}
          >
            <PageHeader
                className="site-layout-background"

                style={{
                  color: '#fff',
                  background: '#000000',
                }}
                ghost={true}

                title={[<><Text style={
                  {color: '#fff',}} >Dashboard</Text></>]}
                subTitle={[
                  <>
                    {environmentZ()}
                  </>]}
                extra={[
                  <Button key="87654df54789" type="button" onClick={logout}>
                    Log out
                  </Button>,
                  ,

                  <Avatar key="83465456789" size={50}
                          src={user.profileImageUrl}></Avatar>,
                  <Text style={
                    {color: '#fff',}
                  } key="8765433df789">{user.email}</Text>,
                ]}
            >
            </PageHeader>
            <Content
                className="site-layout-background"
                style={{
                  margin: '0px 0px 0',
                  overflow: 'initial',
                }}>

              {children}
            </Content>
            <Footer
                style={{
                  textAlign: 'center',
                }}
            >
            </Footer>
          </Layout>
        </Layout>
      </>
  );
}

export default DefaultPageLayout;

const Container = styled.div`
  text-align: center;
`;

const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 90px;
  //margin: 16px;
  //background: rgba(255, 255, 255, 0.2);

`;
