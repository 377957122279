import DefaultPageLayout from '../../components/DefaultPageLayout';
import OrganizationsList from '../../components/organizations/OrganizationsList';

function Organization() {
  return (
    <DefaultPageLayout>
      <OrganizationsList></OrganizationsList>
    </DefaultPageLayout>
  );
}

export default Organization;
