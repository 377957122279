import DefaultPageLayout from '../../components/DefaultPageLayout';
import CreatorEnvironmentSubmission from '../../components/environments/CreatorEnvironmentSubmission';

function EnvironmentSubmissions() {
  return (
    <DefaultPageLayout>
      <CreatorEnvironmentSubmission></CreatorEnvironmentSubmission>
    </DefaultPageLayout>
  );
}
export default EnvironmentSubmissions;
