import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Badge,
  Breadcrumb,
  Button,
  Descriptions,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip, Typography,
} from 'antd';
import { errorNotification, successNotification } from '../Notification';
import {
  approveSubmission,
  getAllSubmissions,
  getSubmission,
} from '../../api/services/creatorServices';
import { useNavigate, useParams} from 'react-router-dom';
import DefaultPageLayout from '../DefaultPageLayout';
import Text from 'antd/es/typography/Text';
import {getEventDetails} from '../../api/services/EventServices';
import ContentLayoutModules from '../modules/ContentLayoutModules';
import {ModalCreateNewEventSpace} from './ModalCreateNewEventSpace';
import {Header} from 'antd/es/layout/layout';
import SpaceProTable from '../spacesPro/SpaceProTable';

function CreatorEnvironmentSubmissionsAprove() {
  const { eventId } = useParams();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({});
  const { Text, Link } = Typography;
  const [visible, setVisible] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getEventDetails(eventId);
      if (response.status === 200) {
        setData(response.data);
        setFetching(false);
      } else {
        setData({});
        setFetching(false);
      }
    } catch (e) {
      errorNotification(
        'Error',
        `an error occurred while fetching data: ${e.message}`
      );
    }
  }, []);

  function onClick(bool) {
    approveSubmission(data.environment.environmentUuid, eventId, bool)
      .then(() => {
        successNotification('Success', 'Submission processed successfully');
        fetchData();
      })
      .catch((e) => {
        errorNotification(
          'Error',
          `an error occurred while approving submission: ${e.message}`
        );
      });
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderData = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <Descriptions
          title="Submission details"
          bordered
          extra={
            <>
            </>
          }
        >
          <Descriptions.Item label="Event ID" span={3}>
            {data.eventId}
          </Descriptions.Item>
          <Descriptions.Item label="Event UUID" span={3}>
            {data.eventName}
          </Descriptions.Item>
          <Descriptions.Item label="Owner UUID" span={3}>
            {data.ownerUserUuid}
          </Descriptions.Item>
          <Descriptions.Item label="Created at" span={3}>
            {data.createdAt}
          </Descriptions.Item>
          <Descriptions.Item label="Updated at" span={3}>
            {data.updatedAt}
          </Descriptions.Item>
          <Descriptions.Item label="Public event" span={3}>
            {data.public.toString()}
          </Descriptions.Item>
          <Descriptions.Item label="Is event published?" span={3}>
            {data.published.toString()}
          </Descriptions.Item>
          <Descriptions.Item label="Link to overview for users" span={3}>
              <Link href={(`https://app.ravel.world/events/${data.eventId}`)} target="_blank" >{`https://app.ravel.world/events/${data.eventId}`} </Link>
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  return (
    <>

          {renderData()}
      <Header
          style={{
            background: '#fff'
          }}>

        <ModalCreateNewEventSpace fetchData={fetchData} eventId={eventId} />

      </Header>
      <SpaceProTable spaceType="EVENT" eventId={eventId} newSpaceDisabled={false}/>

    </>
  );
}

export default CreatorEnvironmentSubmissionsAprove;
