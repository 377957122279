import { Drawer, Input, Col, Select, Form, Row, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { successNotification, errorNotification } from '../Notification';
import { createOrganization } from '../../api/services/organizationServices';
import Cookies from 'js-cookie';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function NewOrganizationDrawerForm({ showDrawer, setShowDrawer, fetchData }) {
  const onCLose = () => setShowDrawer(false);
  const [submitting, setSubmitting] = useState(false);

  const onFinish = (data) => {
    setSubmitting(true);
    console.log(JSON.stringify(data, null, 2));
    createOrganization(data)
      .then(() => {
        onCLose();
        successNotification(
          'Organization created',
          `${data.organizationName} was added to the system`
        );
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        err.response.json().then((res) => {
          console.log(res);
          errorNotification(
            'There was an issue',
            `${res.message} [${res.status}] [${res.error}]`,
            'bottomLeft'
          );
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    alert(JSON.stringify(errorInfo, null, 2));
  };

  return (
    <Drawer
      title="Create new organization"
      width={400}
      onClose={onCLose}
      visible={showDrawer}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onCLose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="organizationName"
              label="organizationName"
              rules={[
                {
                  required: true,
                  message: 'Please enter a name for the new organization',
                },
              ]}
            >
              <Input placeholder="organizationName" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row>{submitting && <Spin indicator={antIcon} />}</Row>
      </Form>
    </Drawer>
  );
}

export default NewOrganizationDrawerForm;
