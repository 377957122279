import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import PageHeaderOpen from './PageHeaderOpen';

const { Content, Footer } = Layout;
const { SubMenu } = Menu;

export default function DefaultPageLayoutOpen({ children }) {
  const [collapsed, setCollapsed] = useState(true);


  return (
    <>
      <Layout style={{ minHeight: '100vh'}}>
        <PageHeaderOpen />
        <Layout >
          <Content
              className="site-layout-background"
              style={{
            margin: '24px 16px 0',
            maxHeight: '90%',
            overflow: 'initial'


          }}>{children}</Content>
        </Layout>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    </>
  );
}
