import client from '../client';
import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
const source = axios.CancelToken.source();

export const getAllSubmissions = async () => {
  try {
    const response = await client.get(
      apiUrl + '/api/v1/environments/admin/all',
      {
        cancelToken: source.token,
      }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getAllCreatorEnvironments = async () => {
  try {
    const response = await client.get(
        apiUrl +
        '/api/v1/environments/admin',
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getAllEnvironments = async () => {
  try {
    const response = await client.get(
        apiUrl +
        '/api/v1/admin/spaces/env/all',
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSpacesForEnvironment = async (environmentUuid) => {
  try {
    const response = await client.get(
        apiUrl +
        `/api/v1/admin/spaces/env/${environmentUuid}/spaces`,
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const setActiveStatusEnvironment = async (environmentUuid, bool) => {
  try {
    const response = await client.put(
        apiUrl +
        `/api/v1/admin/spaces/env/${environmentUuid}/deactivate?activeStatus=${bool}`,
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSubmission = async (submissionUuid) => {
  try {
    const response = await client.get(
      apiUrl + `/api/v1/environments/admin/${submissionUuid}`,
      {
        cancelToken: source.token,
      }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const approveSubmission = async (
  environmentUuid,
  submissionUuid,
  bool
) => {
  try {
    const response = await client.put(
      apiUrl +
        '/api/v1/environments/admin/submissions/' +
        environmentUuid +
        '/' +
        submissionUuid,
      {
        approved: bool,
      }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};


export const getAllAccountRequests = async () => {
  try {
    const response = await client.get(
        apiUrl + '/api/v1/environments/creators/admin',
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getCreatorAccountRequest = async (submissionUuid) => {
  try {
    const response = await client.get(
        apiUrl + `/api/v1/environments/creators/admin/${submissionUuid}`,
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const approveCreatorAccountRequest = async (
    requestSubmissionUuid
) => {
  try {
    const response = await client.put(
        apiUrl +
        '/api/v1/environments/creators/admin/' +
        requestSubmissionUuid
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
