import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
  Badge,
  Breadcrumb,
  Button,
  Descriptions,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { errorNotification, successNotification } from '../Notification';
import {
  approveCreatorAccountRequest,
  approveSubmission,
  getAllSubmissions, getCreatorAccountRequest,
  getSubmission,
} from '../../api/services/creatorServices';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultPageLayout from '../DefaultPageLayout';
import Text from 'antd/es/typography/Text';

function CreatorAccountRequestApprove() {
  const navigate = useNavigate();
  const { submissionUuid } = useParams();
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const response = await getCreatorAccountRequest(submissionUuid);
      if (response.status === 200) {
        setData(response.data);
        setFetching(false);
        successNotification("Succes", "Submissions retrieved")
      } else {
        setData({});
        setFetching(false);
      }
    } catch (e) {
      errorNotification(
        'Error',
        `an error occurred while fetching data: ${e.message}`
      );
    }
  }, []);

  function onClick() {
    approveCreatorAccountRequest(submissionUuid)
      .then(() => {
        successNotification('Success', 'Submission processed successfully');
        navigate(-1);
      })
      .catch((e) => {
        errorNotification(
          'Error',
          `an error occurred while approving submission: ${e.message}`
        );
      });
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderData = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <Descriptions
          title="Submission details"
          bordered
          extra={
            <>
              <Popconfirm
                placement="topRight"
                title={`Are you sure to decline submission?`}
                onConfirm={() => onClick(true)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Approve submission</Button>
              </Popconfirm>
            </>
          }
        >
          <Descriptions.Item label="Creator name" span={3}>
            {data.creatorName}
          </Descriptions.Item>
          <Descriptions.Item label="Creator bio" span={3}>
            {data.creatorBio}
          </Descriptions.Item>
          <Descriptions.Item label="Submission UUID" span={3}>
            {data.submissionUuid}
          </Descriptions.Item>
          <Descriptions.Item label="Created at" span={3}>
            {data.created_at}
          </Descriptions.Item>
          <Descriptions.Item label="Updated at" span={3}>
            {data.updatedAt}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            <Badge status="processing" text="in progress" />
          </Descriptions.Item>
          <Descriptions.Item label="Approved" span={3}>
            {data.approved.toString()}
          </Descriptions.Item>
          <Descriptions.Item label="Denied" span={3}>
            {data.denied.toString()}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  return (
    <>
      <DefaultPageLayout>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Management Console</Breadcrumb.Item>
          <Breadcrumb.Item>Creators</Breadcrumb.Item>
          <Breadcrumb.Item>Submissions</Breadcrumb.Item>
        </Breadcrumb>
        {renderData()}
      </DefaultPageLayout>
    </>
  );
}

export default CreatorAccountRequestApprove;
