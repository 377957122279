import axios from 'axios';
import client from '../client';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
const token = Cookies.get('token');

const getOrganizations = async () => {
  try {
    const { data } = await axios.get(apiUrl + '/api/v1/admin/organizations', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getOrganizationUsers = async (payload) => {
  try {
    const { data } = await axios.get(
      apiUrl + '/api/v1/organizations/organizations/' + payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const createOrganization = async (payload) => {
  try {
    const { responseData } = await client.post(
      apiUrl + '/api/v1/admin/organizations',
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return responseData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const addUserToOrg = async (payload) => {
  try {
    const { responseData } = await client.post(
      apiUrl + '/api/v1/admin/organizations/users',
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return responseData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export {
  getOrganizations,
  getOrganizationUsers,
  createOrganization,
  addUserToOrg,
};
