import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import NormalLoginForm from './pages/login/Login';
import Users from './pages/users/Users';
import Organizations from './pages/organizations/Organizations';
import Modules from './pages/modules/Modules';
import UserInvites from './pages/users/UserInvites';
import ApplicationRoles from './pages/applicationAuth/ApplicationRoles';
import ApplicationPermissions from './pages/applicationAuth/ApplicationPermissions';
import Dashboard from './pages/dashboard/Dashboard';
import SpacesLight from './pages/spacesLight/SpacesLight';
import SpacesPro from './pages/spacesPro/SpacesPro';
import SpacesProOrg from './pages/spacesPro/SpacesProOrg';
import OrganizationUsers from './pages/organizations/OrganizationUsers';
import AuthContextProvider, { AuthContext } from './context/AuthContext';
import EnvironmentSubmissions from './pages/environments/EnvironmentSubmissions';
import CreatorSubmissionAprove from './components/environments/CreatorEnvironmentSubmissionsAprove';
import CreatorAccountRequestApprove
  from './components/creators/CreatorAccountRequestApprove';
import CreatorsAccountRequests from './pages/creators/CreatorsAccountRequests';
import BuildDetail from './components/devtools/BuildDetail';
import DevToolsBuildsOverview from './pages/DevTools/DevToolsBuildsOverview';
import CreatorsEnvironmentsOverview
  from './components/environments/CreatorsEnvironmentsOverview';
import ApiDocumentation from './pages/apiDocumentation/ApiDocumentation';
import PhotonAnalyticsROOMS from './pages/photonAnalytics/PhotonAnalyticsROOMS';
import WebGLViewer from './components/web-gl/WebGLViewer';
import {Events} from './pages/events/Events';
import {EventsDetails} from './pages/events/EventsDetails';
import {Stats} from './pages/Stats/Stats';

function App() {
  const { isAuth } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Routes>
          <Route path="/login" element={<NormalLoginForm />} />
          <Route path="/users" element={<Users />} />
          <Route
            path="/environments/submissions/:submissionUuid"
            element={
              <PrivateRoute>
                {' '}
                <CreatorSubmissionAprove />
              </PrivateRoute>
            }
          />
          <Route
              path="/creators/accounts/requests/:submissionUuid"
              element={
                <PrivateRoute>
                  {' '}
                  <CreatorAccountRequestApprove />
                </PrivateRoute>
              }
          />
          <Route
              path="/environments"
              element={
                <PrivateRoute>
                  {' '}
                  <CreatorsEnvironmentsOverview />
                </PrivateRoute>
              }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                {' '}
                <Users />
              </PrivateRoute>
            }
          />
          <Route
              path="/dev-tools/builds"
              element={
                <PrivateRoute>
                  {' '}
                  <DevToolsBuildsOverview />
                </PrivateRoute>
              }
          />
          <Route
              path="/dev-tools/builds/:buildUuid"
              element={
                <PrivateRoute>
                  {' '}
                  <BuildDetail />
                </PrivateRoute>
              }
          />
          <Route
            path="/userInvites"
            element={
              <PrivateRoute>
                {' '}
                <UserInvites />
              </PrivateRoute>
            }
          />
          <Route
            path="/organizations"
            element={
              <PrivateRoute>
                {' '}
                <Organizations />
              </PrivateRoute>
            }
          />
          <Route
            path="/organizationUsers"
            element={
              <PrivateRoute>
                {' '}
                <OrganizationUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/modules"
            element={
              <PrivateRoute>
                {' '}
                <Modules />
              </PrivateRoute>
            }
          />
          <Route
              path="/events"
              element={
                <PrivateRoute>
                  {' '}
                  <Events />
                </PrivateRoute>
              }
          />
          <Route
              path="/events/:eventId"
              element={
                <PrivateRoute>
                  {' '}
                  <EventsDetails />
                </PrivateRoute>
              }
          />
          <Route
            path="/applicationRoles"
            element={
              <PrivateRoute>
                {' '}
                <ApplicationRoles />
              </PrivateRoute>
            }
          />
          <Route
            path="/applicationPermissions"
            element={
              <PrivateRoute>
                {' '}
                <ApplicationPermissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/spaceslight"
            element={
              <PrivateRoute>
                {' '}
                <SpacesLight />
              </PrivateRoute>
            }
          />
          <Route
            path="/spaces"
            element={
              <PrivateRoute>
                {' '}
                <SpacesPro />
              </PrivateRoute>
            }
          />
          <Route
              path="/spaces/viewer/:spaceId"
              element={
                <PrivateRoute>
                  {' '}
                  <WebGLViewer />
                </PrivateRoute>
              }
          />
          <Route
            path="/spaces/organizations"
            element={
              <PrivateRoute>
                {' '}
                <SpacesProOrg />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                {' '}
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
              path="/api-docs"
              element={
                <PrivateRoute>
                  {' '}
                  <ApiDocumentation />
                </PrivateRoute>
              }
          />

          <Route
            path="/environments/submissions"
            element={
              <PrivateRoute>
                {' '}
                <EnvironmentSubmissions />
              </PrivateRoute>
            }
          />
          <Route
              path="/creators/accounts/requests"
              element={
                <PrivateRoute>
                  {' '}
                  <CreatorsAccountRequests/>
                </PrivateRoute>
              }
          />
          <Route
              path="/photon/analytics"
              element={
                <PrivateRoute>
                  {' '}
                  <PhotonAnalyticsROOMS/>
                </PrivateRoute>
              }
          />
          <Route
              path="/stats"
              element={
                <PrivateRoute>
                  {' '}
                  <Stats />
                </PrivateRoute>
              }
          />

          <Route
            path="/"
            element={
              <PrivateRoute>
                {' '}
                <Stats/>
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  );
}
export default App;

function PrivateRoute({ children }) {
  const { isAuth } = useContext(AuthContext);
  return isAuth ? children : <Navigate to="/login" />;
}
