import { Button, Card, Empty } from 'antd';
import Meta from 'antd/es/card/Meta';
import styled from 'styled-components';
import SpaceProDetailsDrawer from './SpaceProDetailsDrawer';
import {useEffect, useState} from 'react';
import SpaceProDynamicContentDrawer from './SpaceProDynamicContentDrawer';
import {infoNotification} from '../Notification';

export const SpacesCard = ({ fetching, data, setDataUpdated }) => {
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState({});
  const [showDynamicContentDrawer, setShowDynamicContentDrawer] = useState(false);


  function refreshData() {
    setDataUpdated(true);
    setSelectedSpace({});
  }

  function handleShowDetails(space) {
    setSelectedSpace(space);
    setShowDetailsDrawer(true);
  }

  function handleShowDynamicContent(space) {
    infoNotification("handleShowDynamicContent")
    setSelectedSpace(space);
    setShowDynamicContentDrawer(true);
  }

  useEffect(() => {
    if (!showDetailsDrawer) {
      setSelectedSpace({});
    }
  }, [showDetailsDrawer]);



  useEffect(() => {
    if (!showDynamicContentDrawer) {
      setSelectedSpace({});
    }
  }, [showDynamicContentDrawer]);

  const renderCard = () => {
    if (fetching) {
      return (
        <>
          <Card style={{ width: 300, marginTop: 16 }} loading={fetching}></Card>
        </>
      );
    }
    if (data.length <= 0) {
      return (
        <>
          <Empty />
        </>
      );
    }
    data.sort((a,b) => a.spaceName - b.spaceName);
    return (
      <>
        <SpaceProDetailsDrawer
          showDetailsDrawer={showDetailsDrawer}
          setShowDetailsDrawer={setShowDetailsDrawer}
          spaceUuid={selectedSpace.spaceUuid}
          refreshOverview={refreshData}
          setSelectedSpace={setSelectedSpace}
        />
        <SpaceProDynamicContentDrawer
          showDetailsDrawer={showDynamicContentDrawer}
          setShowDetailsDrawer={setShowDynamicContentDrawer}
          spaceUuid={selectedSpace.spaceUuid}
          refreshOverview={refreshData}
          setSelectedSpace={setSelectedSpace}
          setDataUpdated={setDataUpdated}
        />
        {data?.map((item, index) => {
          return (
            <Card
              style={{
                // minHeight: 200,
                width: 300,
                marginTop: 16,
              }}
              key={index}
              cover={
                <img
                  alt={item.environmentPro.name}
                  src={item.environmentPro.imageUrl}
                />
              }
            >
              <Meta title={item.spaceName} />
              <ContentCard>
                <p>{item.description} </p>
              </ContentCard>
              <FooterCard
                style={{
                  background: '#fff',
                }}
              >
                <Button onClick={() => handleShowDetails(item)}>
                  Show details
                </Button>
                {item.environmentPro.dynamicContent && (
                    <>
                      <Button onClick={() => handleShowDynamicContent(item)}>
                        Show content
                      </Button>
                    </>

                )}
              </FooterCard>
            </Card>
          );
        })}
      </>
    );
  };

  return <>
    {renderCard()}
  </>;
};



const FooterCard = styled.div`
  margin-top: 15px;
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  //gap: 4px 4px;
  //flex-wrap: wrap;
  //justify-content: end;
`;

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  margin-top: 15px;
`;
