import DefaultPageLayout from '../../components/DefaultPageLayout';
import ContentLayoutSpaces
  from '../../components/spacesPro/ContentLayoutSpaces';
import SpaceProTable from '../../components/spacesPro/SpaceProTable';


function SpacesPro() {
  return (
    <DefaultPageLayout>
      <ContentLayoutSpaces>
        <SpaceProTable spaceType="PUBLIC" newSpaceDisabled={true} />
      </ContentLayoutSpaces>
    </DefaultPageLayout>
  );
}


export default SpacesPro;
