import Cookies from 'js-cookie';
import client from '../client';
const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
const token = Cookies.get('token');



export const getAllModules = async () => {
  const response = await client.get(`${apiUrl}/api/v1/subscriptions/modules/users/admin`);
  return response;
}


export const addOrganizationToModule = async (organizationId, moduleName) => {
  try {


  const response = await client.post(`${apiUrl}/api/v1/subscriptions/modules/organizations/${organizationId}?moduleName=${moduleName}`);
  return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
}


export const removeOrganizationFromModule = async (organizationId, moduleName) => {
  try {
    const response = await client.delete(`${apiUrl}/api/v1/subscriptions/modules/organizations/${organizationId}?moduleName=${moduleName}`);
  return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
}
