import client from '../client';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;


export const getTokenGatedInformation = async (spaceUuid) => {
  const response = await client.get(`${apiUrl}/api/v1/spaces/pro/${spaceUuid}/gate`);
  return response;
}

export const setTokenGatedAccess = async (spaceUuid, body) => {
  const response = await client.post(`${apiUrl}/api/v1/spaces/pro/${spaceUuid}/gate`
  , body
  );
  return response;
}
