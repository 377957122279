import {useCallback, useEffect, useState} from 'react';
import {getUserFiles} from '../api/services/UserFiles';
import {errorNotification, infoNotification} from './Notification';
import {Select} from 'antd';
import {
  setDynamicContentForSpace,
} from '../api/services/DynamicContentServices';

function UserFiles({spaceUuid, uniqueName, selectedType}) {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);

  const allowed2DFileTipes = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.txt'];
  const allowed3DFileTipes = ['.glb', '.gltf', '.obj'];


  const fetchData = useCallback(async () => {
    try {
      const response = await getUserFiles();

      if (response.status === 200) {
        if(selectedType === '2D'){
          const filteredData = response.data.filter((item) => {
            const fileExtension = item.fileName.slice(item.fileName.lastIndexOf('.'));
            return allowed2DFileTipes.includes(fileExtension);
          } );
          setData(filteredData);
        }
        if(selectedType === '3D'){
          const filteredData = response.data.filter((item) => {
            const fileExtension = item.fileName.slice(item.fileName.lastIndexOf('.'));
            return allowed3DFileTipes.includes(fileExtension);
          } );
          setData(filteredData);
        }
      } else {
        setData([]);
      }
    } catch (error) {
      errorNotification('Files could not be loaded');
    }
  }, []);

  useEffect(() => {
    fetchData().then((r) => setFetching(false));
  }, []);

  const onChange = async (value) => {
    const selectedFile = data.filter((item) => item.id === value)[0];
    await setDynamicContentForSpace('USERFILE', spaceUuid, value, uniqueName,
        selectedType, selectedFile);
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
      <>
        <Select
            style={{width: '100%'}}
            size={'large'}
            loading={fetching}
            showSearch
            placeholder="Select a file"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase()
                    .includes(input.toLowerCase())
            }
            options={data.map((item) => {
              return {
                label: item.fileName,
                value: item.id,
                key: item.id,
              };
            })}
        />
      </>
  );

}

export default UserFiles;
