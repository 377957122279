import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Cookies from 'js-cookie';
import client from '../../api/client';
import {useEffect, useState} from 'react';
import {Select} from 'antd';
import Text from 'antd/es/typography/Text';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import ContentLayoutApiDocumentation from './ContentLayoutApiDocumentation';
import {Header} from 'antd/es/layout/layout';


// https://www.npmjs.com/package/swagger-ui-react
const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

function ApiDocumentation() {
  const [swagger, setSwagger] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [state, setState] = useState(false)
  const subdomain  = /:\/\/([^\/]+)/.exec(window.location.href)[1];

  const token = Cookies.get('token');
  const requestInterceptor = (req) => (
      {
        ...req,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      }
  );


 const fetchData = async (value) => {
    try {
      const response = await client.get(
          value,
      );
      setState(true);
      setSwagger(response.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

 function renderApiDocs() {
   if (!state) {
     return <><Text type={'warning'}> no documentation selected</Text></>
   }
   if (loading) {
      return <div>Loading...</div>;
   }
    return (
        <SwaggerUI
            requestInterceptor={requestInterceptor}
            showMutatedRequest={true}
            displayRequestDuration={true}
            docExpansion={'list'}
            persistAuthorization={true}
            withCredentials={true}
            filter={true}
            spec={swagger} />
    );
  }

  useEffect(() => {
    // fetchData();
  }, []);

 function onChange(value){
    setSelectedOption(value);
    fetchData(value);
 }

  function onSearch(val) {
    console.log('search:', val);
  }


  return (
    <DefaultPageLayout>
      <ContentLayoutApiDocumentation>
        <Header
            style={{
              background: '#fff',
            }}>
          <Select

              showSearch
              placeholder="Select documentation"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                  option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
              }
          >
            <Select.Option
                key="134"
                value={`${apiUrl}/v3/api-docs`}>
              Monolith
            </Select.Option>
            <Select.Option
                key="234523"
                value={`${apiUrl}/environments/api-docs`}>
              Environments
            </Select.Option>

            {subdomain === 'dashboard-dev.ravel.systems' ? (
                <>

                  <Select.Option
                      key="2234533"
                      value={`${apiUrl}/dev-tools/api-docs`}>
                    Dev Tools
                  </Select.Option>
                </>
                ) : (
                    <>
                    </>
                )}

          </Select>

        </Header>

        {renderApiDocs()}

      </ContentLayoutApiDocumentation>

    </DefaultPageLayout>
  );
}

export default ApiDocumentation;
