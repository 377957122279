import {
  Drawer,
  Input,
  Col,
  Select,
  Form,
  Row,
  Button,
  Spin,
  Switch,
  Cascader,
} from 'antd';
import {LoadingOutlined, UserAddOutlined} from '@ant-design/icons';
import {useContext, useEffect, useState} from 'react';
import { successNotification, errorNotification } from '../Notification';
import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';
import {
  createPublicSpace,
  createSpaceProOrganization,
} from '../../api/services/spacesProServices';
import { getOrganizations } from '../../api/services/organizationServices';
import { getEnvPro } from '../../api/services/environmentProServices';
import Cookies from 'js-cookie';
import {AuthContext} from '../../context/AuthContext';

const { Option } = Select;
const userUUID = Cookies.get('userUUID');

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function NewSpacePublic({ showDrawer, setShowDrawer, fetchData }) {
  const {user} = useContext(AuthContext);
  const onCLose = () => setShowDrawer(false);
  const [submitting, setSubmitting] = useState(false);
  const currentCount = (e) => {
    console.log('Change:', e.target.value);
  };
  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  const [options, setOptions] = useState([]);

  const [environments, setEnvironments] = useState([]);

  const fetchOrganizations = () => {

    getOrganizations()
      .then((data) => {
        console.log(data);
        setOptions(data);
      })
      .catch((err) => {});
  };

  const fetchEnvironments = () =>
    getEnvPro()
      .then((environments) => {
        console.log(environments);
        setEnvironments(environments);
      })
      .catch((err) => {});

  useEffect(() => {
    if(showDrawer === true){
      fetchEnvironments();
      fetchOrganizations();
    }
  }, [showDrawer]);

  function filter(inputValue, path) {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

  const onFinish = (data) => {
    setSubmitting(true);
    createPublicSpace(
        data.spaceName,
        data.description,
        false,
        "PUBLIC",
        data.environmentUuid,
        "GUEST",
        data.userUUID,
    )
      .then((response) => {
        console.log('Space Pro Created');
        onCLose();
        successNotification(
          'SpacePro created',
          `${data.spaceName} was added to the system`
        );
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        err.response.json().then((res) => {
          console.log(res);
          errorNotification(
            'There was an issue',
            `${res.message} [${res.status}] [${res.error}]`,
            'bottomLeft'
          );
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // alert(JSON.stringify(errorInfo, null, 2));
  };

  return (
    <Drawer
      title="Add new public Space"
      width={400}
      onClose={onCLose}
      visible={showDrawer}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onCLose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="spaceName"
              label="spaceName"
              rules={[
                {
                  required: true,
                  message: 'Please enter a name for the new Space',
                },
              ]}
            >
              <Input placeholder="Space Name" />
            </Form.Item>
            <Form.Item
              name="description"
              label="description"
              rules={[{ required: true, message: 'Please enter description' }]}
            >
              <Input.TextArea
                showCount
                maxLength={2000}
                placeholder="Description of the Space"
              />
            </Form.Item>

            <Form.Item
              name="environmentUuid"
              label="environmentName"
              rules={[
                {
                  required: true,
                  message: 'Please provide an existing environmentName',
                },
              ]}
            >
              <Select
                options={environments}
                fieldNames={{
                  label: 'name',
                  value: 'environmentUuid',
                }}
                placeholder="Please select"
                showSearch={{ filter }}
                onSearch={(value) => console.log(value)}
              />
            </Form.Item>
            <Paragraph>
              Your own userUUID is:{' '}
              <Text code copyable>
                {user.userUUID}
              </Text>{' '}
            </Paragraph>

            <Form.Item
              name="userUUID"
              label="userUUID"
              rules={[
                { required: true, message: 'Please enter an userUuid' },
              ]}
            >
              <Input placeholder="userUUID" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row>{submitting && <Spin indicator={antIcon} />}</Row>
      </Form>
    </Drawer>
  );
}

export default NewSpacePublic;
