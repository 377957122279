import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import TextArea from 'antd/es/input/TextArea';
import {addPermission} from '../../api/services/appAuthServices';
import {errorNotification, successNotification} from '../Notification';
import {createNewEvent} from '../../api/services/EventServices';
import {Option} from 'antd/es/mentions';
import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';
import {AuthContext} from '../../context/AuthContext';
import {getEnvPro} from '../../api/services/environmentProServices';
import {LoadingOutlined} from '@ant-design/icons';
import {createNewEventSpace} from '../../api/services/spacesProServices';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


export const ModalCreateNewEventSpace = ({ eventId, fetchData}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const {user} = useContext(AuthContext);
  const [environments, setEnvironments] = useState([]);


  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(false);
    setVisible(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    submitToApi(values);
  }

  const handleCancel = () => {
    setLoading(false);
    setVisible(false);
  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const submitToApi = (values) => {
    createNewEventSpace(
        values.spaceName,
        values.description,
        false,
        "EVENT",
        values.environmentUuid,
        "GUEST",
        values.userUUID,
        eventId

    )
        .then((response) => {
          if(response.status === 409) {
            errorNotification(response.data.status + " " + response.data.error,
                response.data.message, 'bottomRight');
            setLoading(false);
          }
            if(response.status === 201){
              handleOk();
            setLoading(false);
            successNotification(
                'Event successfully created',
                `${values.eventName} was added to the system`
            );
            fetchData();
          }
        }
        )
        .catch((err) => {
          console.log(err);
          err.response.json().then((res) => {
            setLoading(false);
            console.log(res);
            errorNotification(
              'There was an issue',
              `${res.message} [${res.status}] [${res.error}]`,
              'bottomRight'
            );
          });
        })
  }

  const fetchEnvironments = () =>
      getEnvPro()
          .then((environments) => {
            setEnvironments(environments);
          })
          .catch((err) => {});

  useEffect(() => {
    fetchEnvironments();
  }, []);

  function filter(inputValue, path) {
    return path.some(
        (option) =>
            option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }



  const renderForm = () => {
    return (
        <>
          <Form
              layout="vertical"
              onFinishFailed={onFinishFailed}
              onFinish={submitToApi}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                    name="spaceName"
                    label="spaceName"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a name for the new Space',
                      },
                    ]}
                >
                  <Input placeholder="Space Name" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="description"
                    rules={[{ required: true, message: 'Please enter description' }]}
                >
                  <Input.TextArea
                      showCount
                      maxLength={2000}
                      placeholder="Description of the Space"
                  />
                </Form.Item>

                <Form.Item
                    name="environmentUuid"
                    label="environmentName"
                    rules={[
                      {
                        required: true,
                        message: 'Please provide an existing environmentName',
                      },
                    ]}
                >
                  <Select
                      options={environments}
                      fieldNames={{
                        label: 'name',
                        value: 'environmentUuid',
                      }}
                      placeholder="Please select"
                      showSearch={{ filter }}
                      onSearch={(value) => console.log(value)}
                  />
                </Form.Item>
                <Paragraph>
                  Your own userUUID is:{' '}
                  <Text code copyable>
                    {user.userUUID}
                  </Text>{' '}
                </Paragraph>

                <Form.Item
                    name="userUUID"
                    label="userUUID"
                    rules={[
                      { required: true, message: 'Please enter an userUuid' },
                    ]}
                >
                  <Input placeholder="userUUID" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row>{loading && <Spin indicator={antIcon} />}</Row>
          </Form>
        </>
    )
  }

  return (
      <>
        <Button type="primary" onClick={showModal}>
          Add space to event
        </Button>
        <Modal
            visible={visible}
            title="Create new event"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
              >
                Done
              </Button>,
            ]}
        >
          {renderForm()}

        </Modal>
      </>
  );
};


