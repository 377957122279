import {errorNotification, infoNotification} from '../components/Notification';

 function ValidateIfIsDev(authorities){

   if(authorities === undefined){
     return false;
   } else{
     if (authorities.includes('dev:access')) {
       return true;
     } else if (authorities.includes('dev_ext:access')) {
       return true;
     } else {
       errorNotification('No access');
       return false;
     }
   }


}

export default ValidateIfIsDev;

