import { useEffect, useState } from 'react';
import { infoNotification, successNotification } from '../Notification';
import {
  getOrganizations,
  getOrganizationUsers,
} from '../../api/services/organizationServices';
import {
  Avatar,
  Breadcrumb,
  Button,
  Empty,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { deActivateAccount, unLockAccount } from '../../api/services/userService';
import axios from 'axios';
import AddUserToOrgDrawer from './AddUserToOrgDrawer';
import ContentLayoutOrganizations from './ContentLayoutOrganizations';
import {Header} from 'antd/lib/layout/layout';
import Text from 'antd/es/typography/Text';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const source = axios.CancelToken.source();
const unLock = async (email, callback) => {
  console.log(email);
  try {
    const { responseData } = await unLockAccount(email, 'true');
    callback();
  } catch (e) {
    console.error(e);
    throw e;
  }
  return function cleanup() {
    source.cancel();
  };
};

const deActivate = async (email, callback) => {
  console.log(email);
  try {
    const { responseData } = await deActivateAccount(email, 'false');
    callback();
  } catch (e) {
    console.error(e);
    throw e;
  }
  return function cleanup() {
    source.cancel();
  };
};

const columns = (fetchUsers) => [
  {
    title: '',
    dataIndex: 'avatar',
    width: 82,
    key: 'avatar',
    render: (text, user) => (
      <Avatar shape="square" size={50} src={user.profileImageUrl} />
    ),
  },

  {
    title: 'firstName',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'lastName',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'organizationRole',
    dataIndex: 'organizationRole',
    key: 'organizationRole',
  },
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'UserUuid',
    dataIndex: 'userUUID',
    key: 'userUUID',
    ellipsis: {
      showTitle: false,
    },
    render: (userUUID) => (
      <Tooltip placement="topLeft" title={userUUID}>
        {userUUID}
      </Tooltip>
    ),
  },
  {
    title: 'joinedAt',
    dataIndex: 'joinedAt',
    key: 'joinedAt',
  },
];

function OrganizationUsersList() {
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const fetchData = (payload) =>
    getOrganizationUsers(payload.organizationName)
      .then((data) => {
        setData(data);
        if (data.length <= 0) {
          infoNotification(
            'No users has been found for: ' + payload.organizationName
          );
        } else {
          successNotification(
            'Successfully loaded users for: ' + payload.organizationName
          );
        }
      })
      .catch((err) => {})
      .finally(() => setFetching(false));

  const fetchOrganizations = () => {
    getOrganizations()
      .then((data) => {
        console.log(data);
        setOptions(data);
      })
      .catch((err) => {})
      .finally(() => setFetching(false));
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  function onChange2(value, selectedOptions) {
    fetchData(selectedOptions);
  }
  function filter(inputValue, path) {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

  const renderUsers = () => {
    if (fetching) {
      return <Spin indicator={antIcon} />;
    }
    if (data.length <= 0) {
      return (
        <>
          <Empty />
        </>
      );
    }
    return (
      <>
        <Table
          dataSource={data}
          columns={columns(fetchData)}
          bordered
          title={() => (
              <>
                <Text></Text>
              </>
          )}
          pagination={{ pageSize: 50 }}
          scroll={{ x: 1500, y: 750 }}
          rowKey={(data) => data.userUUID}
        />
      </>
    );
  };

  return (
    <>




      <ContentLayoutOrganizations>
        <Header

        style={{
          background: '#fff',
        }}
        >
          <Select
              options={options}
              fieldNames={{
                label: 'organizationName',
                value: 'organizationName',
              }}
              onChange={onChange2}
              placeholder="Please select organization"
              showSearch={{ filter }}
              onSearch={(value) => console.log(value)}
              style={{ width: 230 }}
          />
          <>
            <Button
                onClick={() => setShowDrawer(!showDrawer)}
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                size="small"
            >
              Add user to organization
            </Button>
            <AddUserToOrgDrawer
                showDrawer={showDrawer}
                setShowDrawer={setShowDrawer}
                fetchUsers={fetchOrganizations}
            />
          </>
        </Header>

        {renderUsers()}
      </ContentLayoutOrganizations>
    </>
  );
}

export default OrganizationUsersList;
