import client from '../client';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;


export const setDynamicContentForSpace = async (type, spaceUuid, url, uniqueName, contentType, selectedFile) => {
  console.log(contentType)
  try {
    if(contentType === '2D'){
      const response = await client.post(`${apiUrl}/api/v1/spaces/pro/dynamic-contents/${spaceUuid}`,
          {
            environment: {
              content2DSpace: [
                {
                  uniqueName: uniqueName,
                  dynamicContentType: "USERFILE",
                  downloadUrl: `${apiUrl}/api/v2/userfiles/request/${url}`,
                  fileName: selectedFile.fileName,
                  fileId: selectedFile.id
                }
              ]
            }
          }

      );
      return response;
    }
    if(contentType === '3D'){
      const response = await client.post(`${apiUrl}/api/v1/spaces/pro/dynamic-contents/${spaceUuid}`,
          {
            environment: {
              content3DSpace: [
                {
                  uniqueName: uniqueName,
                  dynamicContentType: "USERFILE",
                  downloadUrl: `${apiUrl}/api/v2/userfiles/request/${url}`,
                  fileName: selectedFile.fileName,
                  fileId: selectedFile.id
                }
              ]
            }
          }

      );
      return response;
    }

  } catch (e) {
    console.error(e);
    throw e;
  }
}
