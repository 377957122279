import DefaultPageLayout from '../../components/DefaultPageLayout';
import AppAuthTable from '../../components/applicationAuth/AppAuthTable';
import ContentLayoutAppAuth
  from '../../components/applicationAuth/ContentLayoutAppAuth';

function ApplicationRoles() {
  return (
    <DefaultPageLayout>
      <ContentLayoutAppAuth>
      <AppAuthTable></AppAuthTable>
      </ContentLayoutAppAuth>
    </DefaultPageLayout>
  );
}

export default ApplicationRoles;
