import client from '../client';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;
const source = axios.CancelToken.source();

export const getAllBuilds = async () => {
  try {
    const response = await client.get(
        apiUrl + '/api/v1/dev-tools/builds',
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSpecificBuild = async (buildUuid) => {
  try {
    const response = await client.get(
        apiUrl + `/api/v1/dev-tools/builds/${buildUuid}`,
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const promoteBuildToEnvironment = async (buildUuid, environmentUuid) => {
  try {
    const response = await client.post(
        apiUrl + `/api/v1/dev-tools/builds/promote/${environmentUuid}?buildUuid=${buildUuid}`,
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};


export const getEnvironments = async () => {
  try {
    const response = await client.get(
        apiUrl + '/api/v1/dev-tools/environments',
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const getSpecificEnvironment = async (environmentUuid) => {
  try {
    const response = await client.get(
        apiUrl + `/api/v1/dev-tools/environments/${environmentUuid}`,
        {
          cancelToken: source.token,
        }
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const deletePublishedEnvironment = async (environmentUuid) => {
  try {
    const response = await client.delete(
        `${apiUrl}/api/v1/environments/admin/${environmentUuid}`,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};


export const deleteBuild = async (buildUuid) => {
  try {
    const response = await client.delete(
        `${apiUrl}/api/v1/dev-tools/builds/${buildUuid}`,
    );
    return response;
  } catch (e) {
    console.error(e);
  }
};
