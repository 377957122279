import {useEffect, useState} from 'react';
import {Card, Image, Select} from 'antd';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import ContentLayoutPhotonAnalytics from './ContentLayoutPhotonAnalytics';
import {Header} from 'antd/es/layout/layout';
import axios from 'axios';
import styled from 'styled-components';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import React from 'react';


const photonToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJydWJlbkB4cmJhc2UuY28iLCJqdGkiOiJhN2UwMGU1ZS0yOGZkLTQ4ZTUtYWU5Yy04YzczNjgwMjNmMGUiLCJpYXQiOjE2OTc4OTg3MzksImVtYWlsIjoicnViZW5AeHJiYXNlLmNvIiwiZWd1c3IiOiI1Mjk0NTciLCJyb2xlIjoiY291bnRlci1yZWFkZXIiLCJuYmYiOjE2OTc4OTg3MzksImV4cCI6MTc2MDk3MDczOSwiaXNzIjoiZWdjYUwiLCJhdWQiOiJodHRwczovL2NvdW50ZXIucGhvdG9uZW5naW5lLmNvbS8ifQ.u02oGo5tXEpOOjqjcws-dvDib5CsepVoL1pKmSk5RCo"


function PhotonAnalyticsROOMS() {

  const { RangePicker } = DatePicker;
  const [startingDateTime, setStartingDateTime] =  useState('1h');
  const [endingDateTime, setEndingDateTime] = useState('0h');


  const onChange = (dates, dateStrings) => {
    if (dates) {
      setStartingDateTime( moment(dateStrings[0]).format('YYYY-MM-DD[T]HH:mm:ss'));
      setEndingDateTime( moment(dateStrings[1]).format('YYYY-MM-DD[T]HH:mm:ss'));
    } else {
      console.log('Clear');
    }
  };



  const [range, setRange] = useState("1h");
  const [template, setTemplate] = useState("ccu");


  function getImage(){
    axios({
      method: 'GET',
      url: `https://counter.photonengine.com/Counter/api/graph/app/e2bdfa09-1a97-41ab-acbf-a33e10c96a30/eu/${template}?start=${startingDateTime}&end=${endingDateTime}&width=1000&height=500`,
      headers: {
        Authorization: `${photonToken}`
      },
      responseType: "blob"
    })
        .then(response => {
          var blobURL = URL.createObjectURL(response.data);
          var image = document.getElementById("photonImage");
          image.onload = function(){
            URL.revokeObjectURL(this.src); // release the blob URL once the image is loaded
          }
          image.src = blobURL;
        })
        .catch(error => {
          console.error(error);
        });

  }

  useEffect(() => {
    getImage();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getImage();
    }, 5000)
    return () => clearInterval(interval)
  }, [startingDateTime, endingDateTime, template]);

  useEffect(() => {
    getImage();
  }, [startingDateTime, endingDateTime, template]);




  function onChange2(value) {
    setRange(value);
  }

  function onChangeTemplate(value) {
    setTemplate(value);
  }


  return (
      <DefaultPageLayout>
        <ContentLayoutPhotonAnalytics>
          <Header
              style={{
                background: '#fff',
              }}>


            <Space direction="vertical" size={12}>
              {/*<RangePicker*/}
              {/*    ranges={{*/}
              {/*      Today: [moment(), moment()],*/}
              {/*      'This Month': [moment().startOf('month'), moment().endOf('month')],*/}
              {/*    }}*/}
              {/*    onChange={onChange}*/}
              {/*/>*/}
              <RangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'This week': [moment().startOf('week'), moment().endOf('week')],
                  }}
                  showTime
                  format="YYYY/MM/DD HH:mm:ss"
                  onChange={onChange}
              />
            </Space>
            <Select
                value={template}
                defaultValue="ccu"
                showSearch
                style={{"width": "175px"}}
                placeholder="Select type"
                optionFilterProp="children"
                onChange={onChangeTemplate}
            >
              <Select.Option
                  key="template01"
                  value="ccu">
                CCU
              </Select.Option>

              <Select.Option
                  key="template02"
                  value="trafficmultiplayer">
                trafficmultiplayer
              </Select.Option>


              <Select.Option
                  key="template03"
                  value="disconnectrate">
                dissconnect rate
              </Select.Option>

              <Select.Option
                  key="template04"
                  value="messages">
                messages
              </Select.Option>

              <Select.Option
                  key="template05"
                  value="rooms">
                rooms
              </Select.Option>

              <Select.Option
                  key="template06"
                  value="rejects">
                rejects
              </Select.Option>

              <Select.Option
                  key="template07"
                  value="messagesperroom">
                messages per room
              </Select.Option>


              <Select.Option
                  key="template08"
                  value="messagesperccu">
                messagesperccu
              </Select.Option>



            </Select>

          </Header>
          <Card title={'Online Rooms/Spaces in the past ' + range } bordered={false} style={{ width: "100%" }}>
            <GridItemContent>
              <PhotonImage id="photonImage" src="" ></PhotonImage>
            </GridItemContent>
          </Card>
        </ContentLayoutPhotonAnalytics>

      </DefaultPageLayout>
  );
}

export default PhotonAnalyticsROOMS;

const PhotonImage = styled.img`
  height: 100%;
  width: 1000px;
`;
const GridItemContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 150px;
  width: 100%;
  //position: relative;
`;
