import client from '../client';

const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;



export const getStatsUsersApi = async (days) => {
  return await client.get(`${apiUrl}/api/v1/logs/stats/users?daysFromToday=${days}`);
}

export const getStatsSpacesApi = async (days) => {
  return await client.get(`${apiUrl}/api/v1/logs/stats/spaces?daysFromToday=${days}`);
}



