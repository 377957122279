import {Button, Checkbox, Form, Input, Modal, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import TextArea from 'antd/es/input/TextArea';
import {addPermission} from '../../api/services/appAuthServices';
import {errorNotification, successNotification} from '../Notification';
import {getOrganizations} from '../../api/services/organizationServices';
import {addOrganizationToModule} from '../../api/services/moduleServices';

const ModalAddPermission = ({refreshModules, moduleName}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState([]);


  const showModal = () => {
    setVisible(true);
    fetchOrganizations();
  };

  const handleOk = () => {
    setLoading(false);
    setVisible(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    submitToApi(values);
    fetchOrganizations();
  }

  const handleCancel = () => {
    setLoading(false);
    setVisible(false);
  };

  function filter(inputValue, path) {
    return path.some(
        (option) =>
            option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const fetchOrganizations = () => {
    getOrganizations()
        .then((data) => {
          setOptions(data);
        })
        .catch((err) => {

        });
  };

  const submitToApi = async (values) => {
    await addOrganizationToModule(values.organizationName, moduleName)
        .then((response) => {
              if (response.status === 409) {
                errorNotification(response.data.status + " " + response.data.error,
                    response.data.message, 'bottomRight');
                setLoading(false);
              }
              if (response.status === 201) {
                setLoading(false);
                successNotification(
                    'Organization successfully added');
                refreshModules();
              }
            }
        )
        .catch((err) => {
          console.log(err);
          err.response.json().then((res) => {
            setLoading(false);
            console.log(res);
            errorNotification(
                'There was an issue',
                `${res.message} [${res.status}] [${res.error}]`,
                'bottomRight'
            );
          });
        })
  }

  const renderForm = () => {
    return (
        <>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                name="organizationName"
                label="organizationName"
                rules={[
                  { required: true, message: 'Please enter organizationName' },
                ]}
            >
              <Select
                  options={options}
                  fieldNames={{
                    label: 'organizationName',
                    value: 'organizationId',
                  }}
                  placeholder="Please select"
                  showSearch={{ filter }}
                  onSearch={(value) => console.log(value)}
              />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
            >
              <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}

              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
    )
  }

  return (
      <>
        <Button type="primary" onClick={showModal}>
          Add organization
        </Button>
        <Modal
            visible={visible}
            title="Add organization"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
              >
                Done
              </Button>,
            ]}
        >
          {renderForm()}

        </Modal>
      </>
  );
};

export default ModalAddPermission;
