import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
  Breadcrumb,
  Button, Modal,
  Popconfirm,
  Table, Tag,
  Tooltip,
} from 'antd';
import {errorNotification, infoNotification} from '../Notification';
import { useNavigate } from 'react-router-dom';
import {deleteBuild, getAllBuilds} from '../../api/services/devTools';
import { UploadOutlined} from '@ant-design/icons';
import BuildUploader from './BuildUploader';
import ContentLayoutDevTools from './ContentLayoutDevTools';
import {Header} from 'antd/es/layout/layout';

function BuildsOverview() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const { user } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState('');
  const [data, setData] = useState([]);

  const [visible, setVisible] = useState(false);

  const showDrawer = (item) => {
    navigate('/dev-tools/builds/' + item);
    setVisible(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  function convertDate(date) {
    const d = new Date(date);
    return d.toUTCString();
  }

  async function deleteABuild(item) {
    if(item.environments.length > 0){
      errorNotification('Build is in use');
      return;
    }
    await deleteBuild(item.buildUuid).then((data) => {
      if(data.status === 204){
        infoNotification('Build deleted successfully');
        fetchData();
      } else{
        errorNotification('Error deleting build');
      }
    });
  }


  function deleteAllBuilds(){
    data.filter((item) => item.environments.length === 0).map(async (item) => {
      const d = new Date(item.createdAt);
      const date = d.toUTCString();
      const dateNow = new Date();
      const diffTime = Math.abs(dateNow - d);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 90) {
        await deleteABuild(item);
      }
    } );
  }

  const columns = (fetchData) => [
    {
      title: 'hashId',
      dataIndex: 'hashId',
      key: 'hashId',
      ellipsis: {
        showTitle: true,
      },
      render: (hashId) => (
          <Tooltip placement="topLeft" title={hashId}>
            {hashId}
          </Tooltip>
      ),
    },
    {
      title: 'buildUuid',
      dataIndex: 'buildUuid',
      key: 'buildUuid',
      ellipsis: {
        showTitle: false,
      },
      render: (buildUuid) => (
        <Tooltip placement="topLeft" title={buildUuid}>
          {buildUuid}
        </Tooltip>
      ),
    },
    {
      title: 'uploadType',
      dataIndex: 'uploadType',
      render: (item) => item,
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      // key: 'createdAt',
      render: (item) => (
          <>
            <div>{convertDate(item)}</div>
          </>
      ),
    },
    {
      title: ' environments',
      dataIndex: 'environments',
      // key: 'authorities',
      filterMode: 'tree',
      filterSearch: true,
      render: (environments) => (
          <>
            {environments.map((tag) => {
              let color = tag;
              if (tag.environmentName === 'production') {
                color = 'green';
              }
              if (tag.environmentName === 'development') {
                color = 'blue';
              }
              if (tag.environmentName === 'demo') {
                color = 'orange';
              }
              return (
                  <Tag  color={color} key={tag.environmentUuid}>
                    {tag.environmentName}
                  </Tag>
              );
            })}
          </>
      ),
    },
    {
      title: 'metadataArchive',
      dataIndex: 'metadataArchive',
      // key: 'metadataArchive',
      render: (item) => (

          <>
            <div>
              {item === null ? '' : item.archiveS3Key}
            </div>
          </>
      ),
    },
    {
      title: 'Actions', render(item) {
        return (
            <>
              <Button
                  onClick={() => showDrawer(item.buildUuid)}
                  key={`a-${item.buildUuid}`}
              >
                Details
              </Button>
              <Popconfirm
                  placement="topRight"
                  title={`Are you sure delete this build?`}
                  onConfirm={() => deleteABuild(item)}
                  okText="Yes"
                  cancelText="No"
              >

                <Button danger >
                  Delete
                </Button>
              </Popconfirm>
            </>);
      },
    },
  ];




  const fetchData = useCallback(async () => {
    try {
      const response = await getAllBuilds();
      if (response.status === 200) {
        if (response.data.length > 0) {
         setData(response.data);
          setFetching(false);
        } else {
          setData([]);
          setFetching(false);
        }
      }
    } catch (e) {
      errorNotification(
        'Error',
        `an error occurred while fetching data: ${e.message}`
      );
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderData = () => {
    if (fetching) {
      return <div>Loading...</div>;
    }
    return (
      <>
        <Table
          dataSource={data}
          columns={columns(fetchData)}
          bordered
          title={() => (
            <>
              {/*<Tag>Number of builds</Tag>*/}
              {/*<Badge count={data.length} className="site-badge-count-4" />*/}
              {/*<Button*/}
              {/*onClick={() => deleteAllBuilds()}*/}
              {/*>*/}
              {/*  Delete all builds*/}
              {/*</Button>*/}
            </>
          )}
          pagination={{ pageSize: 20 }}
          scroll={{ y: 500 }}
          rowKey={(data) => data.buildUuid}
        />
      </>
    );
  };

  return (
    <>
      <ContentLayoutDevTools>
        <Modal
            title="Upload new build"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
          <BuildUploader
              fetchData={fetchData}
          />
        </Modal>
        <Header         style={{
          background: '#fff',
        }}>
          <Popconfirm
              placement="topRight"
              title={`Are you sure to upload a new build`}
              onConfirm={() => showModal()}
              okText="Yes"
              cancelText="No"
          >
            <Button
                type="primary"
                shape="round"
                icon={<UploadOutlined />}
                size="small"
            >
              Upload new build
            </Button>
          </Popconfirm>

        </Header>

        {renderData()}

      </ContentLayoutDevTools>

    </>
  );
}

export default BuildsOverview;
