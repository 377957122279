import client from '../client';
import Cookies from 'js-cookie';

const token = Cookies.get('token');
const apiUrl = process.env.REACT_APP_SYSTEMS_BASE_API_URL;

const getUsers = async () => {
  try {
    const { data } = await client.get(apiUrl + '/api/' + 'v1/admin/users/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getUserInviteList = async () => {
  try {
    const { data } = await client.get(apiUrl + '/api/v1/admin/users/invites', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const addUserSysAdmin = async (payload) => {
  try {
    const { responseData } = await client.post(
      apiUrl + '/api/v1/admin/users/add',
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return responseData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const unLockAccount = async (userUuid, bool) => {
  try {
    await client.put(
        apiUrl + `/api/v2/admin/users/lock/${userUuid}?bool=${bool}`
    );
    // return responseData;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const deActivateAccount = async (userUuid, bool) => {
  try {
    const response = await client.put(
      apiUrl + `/api/v2/admin/users/deactivate/${userUuid}?bool=${bool}`,
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


const deleteAccount = async (userUuid) => {
  try {
    const response = await client.delete(
        apiUrl + `/api/v2/admin/users/delete/${userUuid}`,
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export {
  deleteAccount,
  getUsers,
  getUserInviteList,
  addUserSysAdmin,
  unLockAccount,
  deActivateAccount,
};
