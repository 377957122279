import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { PageHeader, Button, Descriptions, Avatar, Row } from 'antd';
import Cookies from 'js-cookie';

function PageHeaderOpen() {
  const navigate = useNavigate();
  const { isAuth, logout } = useContext(AuthContext);

  const avatarUrl = Cookies.get('imageUrl');
  const currentEnv = Cookies.get('envUrl');

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        ghost={false}
        // onBack={() => window.history.back()}
        title="Ravel Dashboard"
        subTitle={
          <></>
          // `current environment: ${currentEnv}`
        }
        extra={[
          // <Button key="101" type="button" onClick={logout}>
          //   Log out
          // </Button>,
          // ,
          // <Avatar shape="round" size={50} src={avatarUrl}></Avatar>,
        ]}
      ></PageHeader>
    </div>
  );
}
export default PageHeaderOpen;
