import DefaultPageLayout from '../../components/DefaultPageLayout';
import UserInviteList from '../../components/users/UserInviteList';

function UserInvites() {
  return (
    <DefaultPageLayout>
      <UserInviteList></UserInviteList>
    </DefaultPageLayout>
  );
}

export default UserInvites;
