import {
  Button,
  Card,
  Descriptions,
  Drawer, Modal,
  Tag,
  Typography,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {
  errorNotification,
} from '../Notification';
import {getSpaceProWithUuid} from '../../api/services/spacesProServices';
import UserFiles from '../UserFiles';

const {Paragraph, Text, Link} = Typography;

function SpaceProDynamicContentDrawer({
  showDetailsDrawer,
  setShowDetailsDrawer,
  spaceUuid,
  refreshOverview,
  setDataUpdated,

}) {
  const [data, setData] = useState({});
  const [fetching, setFetching] = useState(true);
  const [showSetDynamicContent, setShowSetDynamicContent] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [refreshSpaceDetails, setRefreshSpaceDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedType, setSelectedType] = useState('');

  const showModal = (item, type) => {
    setSelectedItem(item);
    setSelectedType(type);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedItem({});
    setSelectedType('');
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedItem({});
    setSelectedType('');
  };

  function onClose2() {
    setShowDetailsDrawer(false);
  }

  function modalDetails() {
    return (
        <>
          <Text>{selectedType}</Text>
          <UserFiles spaceUuid={spaceUuid} uniqueName={selectedItem.uniqueName}
                     selectedType={selectedType}/>
        </>
    );
  }

  async function getSpaceDetails() {
    try {
      const response = await getSpaceProWithUuid(spaceUuid);
      if (response.status === 200) {
        setData(response.data);
      } else {
        setFetching(false);
        errorNotification('Failed to retrieve space details');
      }
    } catch (e) {
      setFetching(false);
      console.error(e);
    }
  }

  useEffect(() => {
    if (spaceUuid && showDetailsDrawer) {
      getSpaceDetails()
          .finally(() => setFetching(false));
    }
  }, [showDetailsDrawer]);

  function configuredDynamicContentSpace(dynamicDataItem) {
    if (data.dynamicContentSpace) {
      return (
          <>
            yes, dynamic content setup
          </>
      );
    } else {
      return (
          <>
            no dynamic content setup
          </>
      );
    }
  }

  function singleCard(item, type) {
    let filteredItem = {};
    if (type === '2D') {
      filteredItem = data.dynamicContentSpace?.environment?.content2DSpace?.filter(
          (item2) => item2.uniqueName === item.uniqueName)[0];
    }
    if (type === '3D') {
      filteredItem = data.dynamicContentSpace?.environment?.content3DSpace?.filter(
          (item2) => item2.uniqueName === item.uniqueName)[0];

    }

    return (
        <>
          <Card
              type="inner"
              title={
                <>
                  {type === '3D' &&
                      <Tag color="orange">3D</Tag>
                  }
                  {type === '2D' &&
                      <Tag color="magenta">2D</Tag>
                  }
                  Available slot: {item?.uniqueName}
                </>
              }
              extra={
                <>
                  <Button type="primary" onClick={() => showModal(item, type)}>
                    Set content
                  </Button>
                </>
              }
          >
            <Descriptions
                bordered
            >
              {filteredItem &&
                  <>
                    <Descriptions.Item label="File name" span={3}>
                      {filteredItem?.fileName}
                    </Descriptions.Item>
                    <Descriptions.Item label="File Id" span={3}>
                      {filteredItem?.fileId}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type" span={3}>
                      {filteredItem?.dynamicContentType}
                    </Descriptions.Item>
                    <Descriptions.Item label="Download Url" span={3}>
                      {filteredItem?.downloadUrl}
                    </Descriptions.Item>
                  </>

              }
            </Descriptions>
          </Card>
        </>
    );
  }

  function renderCards(type) {
    if (type === '2D') {
      if (data.environmentPro?.dynamicContent?.content2D) {
        return (
            <>
              {data.environmentPro?.dynamicContent?.content2D?.map((item) => (
                  <>
                    {singleCard(item, type)}
                    <br/>
                  </>
              ))}
            </>
        );
      }
    }
    if (type === '3D') {
      if (data.environmentPro?.dynamicContent?.content3D) {
        return (
            <>
              {data.environmentPro?.dynamicContent?.content3D?.map((item) => (
                  <>
                    {singleCard(item, type)}
                    <br/>
                  </>
              ))}
            </>
        );
      }
    }
    return (
        <></>
    );
  }

  const renderDetails = () => {
    if (fetching) {
      return <></>;
    }
    return (
        <>
          <Descriptions
              title="Space Dynamic Content"
              bordered
              extra={
                <>
                </>
              }
          >
          </Descriptions>
          <br/>

          <br/>
          <Card style={{backgroundColor: '#b8cbcb'}}
                title="2D Dynamic Content Environment">
            {renderCards('2D')}
          </Card>
          <br/>
          <br/>
          <Card style={{backgroundColor: '#cbbfb8'}}
                title="3D Dynamic Content Environment">
            {renderCards('3D')}
          </Card>
          <br/>
        </>
    );
  };

  return (
      <>
        <Modal
            destroyOnClose={true}
            title="Set content"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
          {modalDetails()}
        </Modal>
        <Drawer
            width={840}
            placement="right"
            closable={true}
            onClose={() => onClose2()}
            visible={showDetailsDrawer}
            destroyOnClose={true}
        >
          {showDetailsDrawer && <>{renderDetails()}</>}
        </Drawer>

      </>
  );
}

export default SpaceProDynamicContentDrawer;
