import { useEffect, useState } from 'react';
import {
  addUserToOrg,
  getOrganizations,
} from '../../api/services/organizationServices';
import { errorNotification, successNotification } from '../Notification';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function AddUserToOrgDrawer({ showDrawer, setShowDrawer, fetchData }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const onCLose = () => setShowDrawer(false);
  const [submitting, setSubmitting] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchOrganizations = () => {
    getOrganizations()
      .then((data) => {
        console.log(data);
        setOptions(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  function filter(inputValue, path) {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }
  function onChange2(value, selectedOptions) {
    console.log(value, selectedOptions);
  }

  const onFinish = (data) => {
    setSubmitting(true);
    addUserToOrg(data)
      .then(() => {
        onCLose();
        successNotification(
          'User added to organization successfully',
          `${data.userEmail} was added to the organization`
        );
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        err.response.json().then((res) => {
          console.log(res);
          errorNotification(
            'There was an issue',
            `${res.message} [${res.status}] [${res.error}]`,
            'bottomLeft'
          );
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    alert(JSON.stringify(errorInfo, null, 2));
  };

  return (
    <Drawer
      title="Add user to organization"
      width={400}
      onClose={onCLose}
      visible={showDrawer}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onCLose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="userEmail"
              label="userEmail"
              rules={[
                { required: true, message: 'Please enter a email' },
                { type: 'email' },
                {
                  type: 'string',
                  min: 1,
                  max: 50,
                  warningOnly: true,
                  message: 'Too long',
                },
                {
                  type: 'email',
                  warningOnly: true,
                  message: 'Please enter a email',
                },
              ]}
            >
              <Input placeholder="Please enter an email" />
            </Form.Item>

            <Form.Item
              name="organizationName"
              label="organizationName"
              rules={[
                { required: true, message: 'Please enter organizationName' },
              ]}
            >
              <Select
                options={options}
                fieldNames={{
                  label: 'organizationName',
                  value: 'organizationName',
                }}
                onChange={onChange2}
                placeholder="Please select"
                showSearch={{ filter }}
                onSearch={(value) => console.log(value)}
              />
            </Form.Item>
            <Form.Item
              name="organizationRole"
              label="organizationRole"
              rules={[
                {
                  required: true,
                  message: 'Please select a Organization Role',
                },
              ]}
            >
              <Select>
                <Select.Option value="ORGANIZATION_OWNER">Owner</Select.Option>
                <Select.Option value="ORGANIZATION_ADMIN">Admin</Select.Option>
                <Select.Option value="ORGANIZATION_USER">User</Select.Option>
                <Select.Option value="ORGANIZATION_GUEST">Guest</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row>{submitting && <Spin indicator={antIcon} />}</Row>
      </Form>
    </Drawer>
  );
}

export default AddUserToOrgDrawer;
