import {useEffect, useState} from 'react';
import {
  addUserToOrg,
  getOrganizations,
} from '../../api/services/organizationServices';
import {errorNotification, successNotification} from '../Notification';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select, Space,
  Spin,
  Switch,
} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {
  getEnvironments,
  promoteBuildToEnvironment,
} from '../../api/services/devTools';
import Text from 'antd/es/typography/Text';

function DrawerPromoteBuildToEnvironment({
  build,
  showDrawer,
  setShowDrawer,
  fetchData,
}) {
  const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;
  const onCLose = () => setShowDrawer(false);
  const [submitting, setSubmitting] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  const fetchBuildDetails = () => {
    getEnvironments()
        .then((response) => {
          setOptions(response.data);
        })
        .catch((err) => {});
  };

  useEffect(() => {
    fetchBuildDetails();
  }, []);

  function filter(inputValue, path) {
    return path.some(
        (option) =>
            option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  }

  function onChange2(value, selectedOptions) {
    setSelectedOption(selectedOptions.environmentName);
  }

  const onFinish = async (data) => {
    setSubmitting(true);
    console.log(data.environmentUuid);
    try {
      const response = await promoteBuildToEnvironment(build.buildUuid,
          data.environmentUuid);
      if (response.status === 202) {
        onCLose();
        successNotification(
            'Promote build to environment successfully',
            `${build.hashId} has been promoted to ${data.environment}`,
        );
        fetchData();
        setSubmitting(false);
      }
    } catch (e) {
      errorNotification(
          'There was an issue',
          `${e.message} [${e.status}] [${e.error}]`,
          'bottomLeft',
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    alert(JSON.stringify(errorInfo, null, 2));
  };

  return (
      <Drawer
          title={`Promote build with hashId:  ${build.hashId}`}
          width={400}
          onClose={onCLose}
          visible={showDrawer}
          bodyStyle={{paddingBottom: 80}}
          footer={
            <div
                style={{
                  textAlign: 'right',
                }}
            >
              <Button onClick={onCLose} style={{marginRight: 8}}>
                Cancel
              </Button>
            </div>
          }
      >
        <Form
            layout="vertical"
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
            autoComplete="off"
        >
          <Space>
            <Text type="warning">YOU'RE ABOUT TO PROMOTE A BUILD TO AN ENVIRONMENT !</Text>

          </Space>
          <Space>
            <Text type="secondary">This action will go into effect immediately after submission.</Text>

          </Space>

          <Row gutter={16}>
            <Col span={24}>

              <Form.Item
                  name="environmentUuid"
                  label="Choose environment"
                  rules={[
                    {
                      required: true,
                      message: `Please select environment you want to promote this build ${build.hashId}`,
                    },
                  ]}
              >
                <Select
                    options={options}
                    fieldNames={{
                      label: 'environmentName',
                      value: 'environmentUuid',
                    }}
                    onChange={onChange2}
                    placeholder="Please select"
                    showSearch={{filter}}
                    onSearch={(value) => console.log(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Promote to {selectedOption}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row>{submitting && <Spin indicator={antIcon}/>}</Row>
        </Form>
      </Drawer>
  );
}

export default DrawerPromoteBuildToEnvironment;
